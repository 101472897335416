import { Application } from './application.model';
import { BnplSession } from './bnpl-session.model';
import { Mandate } from './mandate.model';
import { Order } from './order.model';
import { Session } from './session.model';

export class BnplPaymentSession {
    application?: Application;
    sessionDetails?: Session;
    bnplSession?: BnplSession;
    order?: Order;
    mandate?: Mandate;

    constructor(obj: any) {
        this.application = obj.application ? new Application(obj.application) : undefined;
        this.sessionDetails = obj.session_details ? new Session(obj.session_details) : undefined;
        this.bnplSession = obj.bnpl_session ? new BnplSession(obj.bnpl_session) : undefined;
        this.order = obj.order;
        this.mandate = obj.mandate ? new Mandate(obj.mandate) : undefined;
    }
}
