import { Account } from './account.model';

export class Mandate {
    // Fintecture account data
    creditorName: string;
    creditorAddress: string;
    creditorIban: string;
    rum: string;
    ics: string;
    // psu account data
    accounts: Account[];
    debtorName: string;
    debtorCompanyName: string;
    debtorAddress: string;
    reference: string;
    date: string;

    constructor(obj: any) {
        this.creditorName = obj.creditorName;
        this.creditorAddress = obj.creditorAddress;
        this.creditorIban = obj.creditorIban;
        this.rum = obj.rum;
        this.ics = obj.ics;
        this.accounts = obj.accounts;
        this.debtorName = obj.debtorName;
        this.debtorCompanyName = obj.debtorCompanyName;
        this.debtorAddress = obj.debtorAddress;
        this.reference = obj.reference;
        this.date = obj.date;
    }
}
