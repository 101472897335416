export class JsonWebToken {
    private token: string;
    private payload: any;

    constructor(token: string) {
        this.token = token;
        this.payload = this.decodeToken();
    }

    toString(): string {
        return this.token;
    }

    /**
     * Returns the expiration time.
     * @returns {number} The expiration time in milliseconds from the current time or 0 if the token is already expired.
     */
    get expiresIn(): number {
        return Math.max(this.payload.exp * 1000 - new Date().getTime(), 0);
    }

    private decodeToken(): any {
        const base64 = this.token.split('.')[1];
        try {
            return JSON.parse(atob(base64));
        } catch (err) {
            throw new Error('Invalid token format');
        }
    }
}
