import { Account } from './account.model';
import { Payment } from './payment.model';

export class AccountInformations {
    payment: Payment;
    accounts: Account[];
    beneficiaryName: string;

    constructor(obj: any) {
        this.payment = obj.payment ? new Payment(obj) : undefined;
        this.accounts = obj.accounts;
        this.beneficiaryName = obj.beneficiaryName;
    }
}
