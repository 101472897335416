export class Beneficiary {
    name: string;
    commercialName?: string;
    street?: string;
    number?: string;
    city?: string;
    country?: string;
    addressComplete: boolean;
    isValidate: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(obj: any) {
        this.name = obj.name;
        if (obj.commercial_name) this.commercialName = obj.commercial_name;
        if (obj.street) this.street = obj.street;
        if (obj.number) this.number = obj.number;
        if (obj.city) this.city = obj.city;
        if (obj.country) this.country = obj.country;
        this.addressComplete = obj.address_complete || false;
        this.isValidate = obj.is_validate || false;
    }

    get displayName(): string {
        return this.commercialName || this.name;
    }

    clone(): Beneficiary {
        return new Beneficiary({
            name: this.name,
            street: this.street,
            number: this.number,
            city: this.city,
            country: this.country,
            commercial_name: this.commercialName,
            is_validate: this.isValidate,
        });
    }

    merge(beneficiary: Beneficiary): Beneficiary {
        if (!beneficiary) {
            return this;
        }

        if (beneficiary.name) this.name = beneficiary.name;
        if (beneficiary.commercialName) this.commercialName = beneficiary.commercialName;
        if (beneficiary.street) this.street = beneficiary.street;
        if (beneficiary.number) this.number = beneficiary.number;
        if (beneficiary.city) this.city = beneficiary.city;
        if (beneficiary.country) this.country = beneficiary.country;
        if (beneficiary.addressComplete) this.addressComplete = beneficiary.addressComplete;
        if (beneficiary.isValidate) this.isValidate = beneficiary.isValidate;

        return this;
    }
}
