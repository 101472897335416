/**
 * Connect payment settings
 */
export class ConnectSettings {
    isViaWeb2App: boolean;
    isSupportChatEnabled: boolean;
    userHash: string;
    stRedesign: boolean;
    connectFintec: boolean;

    constructor(obj) {
        this.isViaWeb2App = obj.is_via_web2app || false;
        this.isSupportChatEnabled = obj.is_support_chat_enabled;
        this.userHash = obj.user_hash;
        this.stRedesign = obj.st_redesign;
        this.connectFintec = obj.connectFintec;
    }
}
