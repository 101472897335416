export enum RefundStatus {
    REFUND_INITIATED = 'refund_initiated',
    REFUND_ACCEPTED = 'refund_accepted',
    REFUND_ABORTED = 'refund_aborted',
    REFUND_PENDING = 'refund_pending',
    REFUND_DELEGATED = 'refund_delegated',
    REFUND_CREATED = 'refund_created',
    REFUND_WAITING = 'refund_waiting',
    REFUND_UNSUCCESSFUL = 'refund_unsuccessful',
    REFUND_PARTIAL = 'refund_partial',
    REFUND_ERROR = 'refund_error',
}
