import { BnplSession, Onboarding, Order, Quote } from '@app-shared/models';

export class Cover {
    quote: Quote;
    order: Order;
    bnplSession: BnplSession;
    onboarding?: Onboarding;

    constructor(obj: any) {
        this.quote = obj.quote ? new Quote(obj.quote) : undefined;
        this.order = obj.order ? new Order(obj.order) : undefined;
        this.bnplSession = obj.bnpl_session ? new BnplSession(obj.bnpl_session) : undefined;
        this.onboarding = obj.onboarding ? new Onboarding(obj.onboarding) : undefined;
    }
}
