import { InjectionToken } from '@angular/core';

export class AppConfig {
    connect_backend_url: string;
    connect_v1_frontend: string;
    assets_url: string;
    env_name: string;
    production: boolean;
    castleIO: string;
    intercom: {
        apiUrl: string;
        appId: string;
    };
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
