import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../app.config';

@Injectable({
    providedIn: 'root',
})
export class ApiUrlsService {
    constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

    getUrls() {
        const CONNECT_BACKEND = this.config.connect_backend_url;

        return {
            get_ab_tests: `${CONNECT_BACKEND}/abtests/testplan/[[:id]]`,
            init_connect_url: `${CONNECT_BACKEND}/connect/v2/init`,
            share_cart: `${CONNECT_BACKEND}/connect/v2/share`,
            get_providers: `${CONNECT_BACKEND}/connect/v2/providers`,
            event_collection: `${CONNECT_BACKEND}/connect/v2/[[:connectId]]/event-collection/[[:event]]`,
            post_survey_rating: `${CONNECT_BACKEND}/connect/v2/ratings`,
            // pis
            get_payment: `${CONNECT_BACKEND}/pis/v2/payment`,
            get_payment_redirection: `${CONNECT_BACKEND}/pis/v2/payment/[[:connectId]]/redirection`,
            update_payment: `${CONNECT_BACKEND}/pis/v2/payment`,
            create_manual_payment_url: `${CONNECT_BACKEND}/pis/v2/payment/create/manual`,
            virtual_iban_manual_payment_url: `${CONNECT_BACKEND}/pis/v2/payment/virtual-iban/manual`,
            init_payment_url: `${CONNECT_BACKEND}/pis/v2/payment/initiate`,
            get_result_url: `${CONNECT_BACKEND}/pis/v2/callback/result`,
            // bnpl
            create_bnpl_payment: `${CONNECT_BACKEND}/bnpl`,
            update_psu_company_info: `${CONNECT_BACKEND}/bnpl/covers/psu-company-information`,
            create_quote: `${CONNECT_BACKEND}/bnpl/covers/quote`,
            get_bnpl_session: `${CONNECT_BACKEND}/bnpl/covers`,
            activate_cover: `${CONNECT_BACKEND}/bnpl/covers/activate`,
            switch_session_to_pis: `${CONNECT_BACKEND}/bnpl/fallback/pis`,
            get_rejected_result: `${CONNECT_BACKEND}/bnpl/covers/reject`,
            get_net_terms_company_coverage: `${CONNECT_BACKEND}/bnpl/companies/[[:companyId]]/coverages`,
            // ais
            authorize_ais: `${CONNECT_BACKEND}/ais/v2`,
            refund_ais_session: `${CONNECT_BACKEND}/refund/v2/ais/session`,
            psu_iban: `${CONNECT_BACKEND}/refund/v2/ais/psu-iban`,
            account_information: `${CONNECT_BACKEND}/refund/v2/ais/accounts-information`,
            get_refunded_payment: `${CONNECT_BACKEND}/refund/v2/[[:sessionId]]/refund-informations`,
            ocr_refund: `${CONNECT_BACKEND}/refund/v2/ocr/refund`,
            initiate_onboarding_refund: `${CONNECT_BACKEND}/refund/v2/payments/[[:connectId]]/onboardings`,
            account_verification: `${CONNECT_BACKEND}/refund/v2/ais/[[:connectionId]]/verifications`,
            // sessions
            sessions_otp: `${CONNECT_BACKEND}/sessions/[[:sessionId]]/otp`,
            sessions_scope: `${CONNECT_BACKEND}/sessions/[[:sessionId]]/scope`,
            // permanent
            create_payment_from_template: `${CONNECT_BACKEND}/permanent`,
            // kyu
            kyu_create_company: `${CONNECT_BACKEND}/kyu/companies`,
            kyu_search_company: `${CONNECT_BACKEND}/kyu/companies`,
        };
    }
}
