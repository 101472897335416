import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { FingerprintService } from '@app-shared/services/device-fingerprint/fingerprint.service';

export const INCLUDE_DEVICE_FINGERPRINT = new HttpContextToken(() => false);

@Injectable()
export class DeviceFingerprintInterceptor implements HttpInterceptor {
    constructor(private fingerprintService: FingerprintService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.context.get(INCLUDE_DEVICE_FINGERPRINT)) {
            return this.fingerprintService.getFingerprintData().pipe(
                switchMap((fingerprint) => {
                    if (!fingerprint) {
                        return next.handle(req);
                    }
                    const reqWithFingerprint = req.clone({
                        setHeaders: { 'connect-fintec': fingerprint },
                    });
                    return next.handle(reqWithFingerprint);
                }),
            );
        }
        return next.handle(req);
    }
}
