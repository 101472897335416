import { Functionality, IsctPolicy, PaymentMethodId, TransferType } from '@app-shared/enums';
import { buildFunctionalities } from '@app-shared/helpers';
import { PaymentMethod } from '@app-shared/models';

export class Application {
    allowRetryOnFailure: boolean;
    appLogo?: string;
    paymentWordingType: 'order' | 'invoice' | 'verification';
    hasInstFeeRefunds: boolean;
    isctPolicy: IsctPolicy;
    functionalities: Functionality[];
    paymentMethods: PaymentMethod[];
    commercialName?: string;
    merchantName?: string;
    withPaymentOtp?: boolean;

    constructor(obj: any) {
        this.allowRetryOnFailure = obj.allow_retry_on_failure;
        this.appLogo = obj.app_logo;
        this.paymentWordingType = obj.payment_wording_type;
        this.hasInstFeeRefunds = obj.has_inst_fee_refunds;
        this.isctPolicy = obj.isct_policy;
        this.functionalities = buildFunctionalities(obj.functionalities);
        this.paymentMethods = this.buildPaymentMethods(obj.payment_methods);
        this.commercialName = obj.commercial_name;
        this.merchantName = obj.merchant_name;
        this.withPaymentOtp = obj.with_payment_otp;

        if (!this.paymentWordingType) {
            this.paymentWordingType = 'order';
        }
    }

    hasApplicationFunctionality(functionality: Functionality): boolean {
        return this.functionalities.includes(functionality);
    }

    isInvoice(): boolean {
        return this.paymentWordingType === 'invoice';
    }

    isOrder(): boolean {
        return this.paymentWordingType === 'order';
    }

    isVerification(): boolean {
        return this.paymentWordingType === 'verification';
    }

    buildPaymentMethods(obj: any): PaymentMethod[] {
        const paymentMethods: PaymentMethod[] = [];

        obj?.forEach((method) => {
            paymentMethods.push({
                ...method,
                transferType: this._getTransferType(method.id),
                translationKey: this._getTranslationKeys(method.id),
            });
        });

        paymentMethods.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

        return paymentMethods.filter((paymentMethod) => paymentMethod.transferType);
    }

    hasMethod(id: PaymentMethodId): boolean {
        return this.paymentMethods.some((method) => method.id === id);
    }

    private _getTransferType(id: PaymentMethodId): TransferType {
        switch (id) {
            case PaymentMethodId.IMMEDIATE_TRANSFER:
                return TransferType.IMMEDIATE_TRANSFER;
            case PaymentMethodId.SMART_TRANSFER:
                return TransferType.SMART_TRANSFER;
            case PaymentMethodId.BNPL:
                return TransferType.BNPL;
        }
    }

    private _getTranslationKeys(id: PaymentMethodId): string {
        switch (id) {
            case PaymentMethodId.IMMEDIATE_TRANSFER:
                return 'pay-by-bank';
            case PaymentMethodId.SMART_TRANSFER:
                return 'classic-bank-transfer';
            case PaymentMethodId.BNPL:
                return 'delayed-payment';
        }
    }
}
