import { ActivatedRoute } from '@angular/router';
import { Application, Payment } from '@app-shared/models';
import { getOrigin } from './origin.helper';
import { Origin } from '@app-shared/enums';

export function paymentHasRedirection(redirectUri: string | URL, application: Application, payment: Payment, activatedRoute: ActivatedRoute): boolean {
    return (
        !!redirectUri?.toString() &&
        (getOrigin(activatedRoute.snapshot) !== Origin.EMAIL || ((!application?.allowRetryOnFailure || payment?.isRetryable) && payment?.isUnsuccessful())) &&
        activatedRoute.snapshot.queryParams?.no_redirect !== 'true'
    );
}
