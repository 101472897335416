import { Params } from '@angular/router';
import { PaymentMethodId, PaymentMethodPlacement, PaymentType } from '@app-shared/enums';
import { checkURL } from '@app-shared/helpers';
import { Payment, Application, Provider } from '@app-shared/models';

export class PaymentCallback {
    application?: Application;
    paymentDetails: Payment;
    provider: Provider;
    redirectUri: URL;
    originUri: URL;
    connectUrl: string;
    connectId: string;
    queryParams?: Params;
    expiryDate?: string;

    constructor(obj: any, params?: Params) {
        this.application = obj.application ? new Application(obj.application) : undefined;
        this.paymentDetails = obj.payment_details ? new Payment(obj.payment_details) : undefined;
        this.provider = obj.provider ? new Provider(obj.provider) : undefined;
        this.connectUrl = obj.connect_url;
        this.connectId = obj.connect_id;
        this.expiryDate = obj.expiry_date;
        this.queryParams = params;

        if (obj.redirect_uri && checkURL(obj.redirect_uri)) {
            this.redirectUri = new URL(obj.redirect_uri);
        }

        if (obj.origin_uri && checkURL(obj.origin_uri)) {
            this.originUri = new URL(obj.origin_uri);
        }

        if (this.originUri) {
            if (this.paymentDetails?.sessionId) {
                this.originUri.searchParams.append('session_id', this.paymentDetails.sessionId);
            }

            this.originUri.searchParams.append('status', this.paymentDetails?.status);

            if (this.queryParams?.state) {
                this.originUri.searchParams.append('state', this.queryParams.state);
            }
        }
    }

    hasReconciledTransferFeature(): boolean {
        const hasSmartTransferMethod = this.application?.hasMethod(PaymentMethodId.SMART_TRANSFER);
        const hasValidPaymentType = [PaymentType.RECONCILED_TRANSFER, PaymentType.PAY_BY_BANK, PaymentType.REQUEST_TO_PAY].includes(
            this.paymentDetails?.providerPaymentType,
        );
        return hasSmartTransferMethod && hasValidPaymentType;
    }

    /**
     * Checks whether ST payment method placement is "fallback" only or "select"+"fallback". Does not check if ST is only method available.
     */
    hasOnlyFallbackSmartTransfer(): boolean {
        const stPaymentMethod = this.application?.paymentMethods?.find((pm) => pm?.id === PaymentMethodId.SMART_TRANSFER);
        if (!stPaymentMethod) {
            return false;
        }
        return (
            stPaymentMethod.placements?.find((placement) => placement === PaymentMethodPlacement.FALLBACK) &&
            !stPaymentMethod.placements.some((placement) => placement === PaymentMethodPlacement.SELECT)
        );
    }

    isRetryablePayment(): boolean {
        return this.application?.allowRetryOnFailure && this.paymentDetails?.isRetryable && !this.paymentDetails.isInitiallyBNPLCollector();
    }
}
