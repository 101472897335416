import { Injectable } from '@angular/core';
import { map, Observable, pairwise, startWith, Subscription } from 'rxjs';

import { PaymentType, PsuType, Scope, TransferType } from '@app-shared/enums';
import { Beneficiary, OtpSessionResponse, PSU } from '@app-shared/models';
import { State } from '@app-shared/libs';
import { omit } from '@app-shared/helpers';
import { unsubscribeOnDestroy } from '@app-shared/decorators';

export interface SessionStore {
    connectId?: string;
    sessionId?: string;
    country?: string;
    scope?: Scope;
    psuType?: PsuType;
    beneficiary?: Beneficiary;
    psu?: PSU;
    paymentType?: PaymentType;
    transferType?: TransferType;
    fromProvidersList?: boolean;
    expiryDate?: string;
    sharedCart?: boolean;
    currentProviderId?: string;
    tracking: {
        pisRefreshPendingTracker?: boolean;
        pisBackToPaymentMethods?: boolean;
    };
    commercialName?: string;
    otp?: OtpSessionResponse;
}

interface StateChanges {
    previous: SessionStore;
    changes: Partial<SessionStore>;
}

@Injectable({ providedIn: 'root' })
export class StoreService {
    private _connectId: string;
    readonly sessionStorageIdKey = 'cid';
    readonly sessionStorageKey = 'c';
    readonly state: State<SessionStore>;
    initial: SessionStore = {
        connectId: undefined,
        sessionId: undefined,
        country: undefined,
        scope: undefined,
        psuType: undefined,
        beneficiary: undefined,
        psu: undefined,
        paymentType: undefined,
        transferType: undefined,
        fromProvidersList: undefined,
        expiryDate: undefined,
        currentProviderId: undefined,
        tracking: {
            pisRefreshPendingTracker: undefined,
            pisBackToPaymentMethods: undefined,
        },
        commercialName: undefined,
        otp: undefined,
    };

    constructor() {
        this.state = new State<SessionStore>(this.initial);
    }

    public initialize(connectId: string): void {
        sessionStorage.setItem(this.sessionStorageIdKey, connectId);
        this._connectId = connectId;

        const session = this.sessionStorage;
        if (session) {
            this.initial = session;
            this.state.update(this.initial);
        }

        this.handleStateChanges();
    }

    public recover(): void {
        this.initialize(sessionStorage.getItem(this.sessionStorageIdKey));
        this.handleStateChanges();
    }

    @unsubscribeOnDestroy
    private handleStateChanges(): Subscription {
        return this.state.get().subscribe((session) => (this.sessionStorage = session));
    }

    get sessionStorage(): SessionStore {
        const key = this.getSessionStorageKey();
        return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : null;
    }

    private set sessionStorage(session: SessionStore) {
        sessionStorage.setItem(this.getSessionStorageKey(), JSON.stringify(session));
    }

    private getSessionStorageKey(): string {
        return `${this.sessionStorageKey}-${this._connectId}`;
    }

    getStateChanges(): Observable<StateChanges> {
        return this.state.get().pipe(
            startWith(this.initial),
            pairwise(),
            map(([previous, current]) => ({
                previous: previous,
                changes: omit(
                    current,
                    Object.entries(previous)
                        .filter(([key, value]) => JSON.stringify(value) === JSON.stringify(current[key]))
                        .map(([key]) => key),
                ),
            })),
        );
    }

    onCloseSession(): void {
        this.sessionStorage = {
            ...this.state.value,
            transferType: null,
        };
    }

    saveState(): void {
        this.state.getOnce().subscribe((session) => (this.sessionStorage = session));
    }
}
