import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlsService } from '@app-shared/constants';
import { VirtualIban } from '@app-shared/models';
import { BehaviorSubject, catchError, distinctUntilChanged, EMPTY, filter, finalize, forkJoin, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { PsuService } from '../psu/psu.service';
import { StoreService } from '../store/store.service';
import { LoaderService } from '../loader/loader.service';
import { LanguageService } from '../language/language.service';
import { unsubscribeOnDestroy } from '@app-shared/decorators';

@Injectable({
    providedIn: 'root',
})
export class VirtualIbanService {
    private headers: HttpHeaders;
    virtualIban = new BehaviorSubject(null);
    requesting = false;

    constructor(
        private http: HttpClient,
        private store: StoreService,
        private psuService: PsuService,
        private loaderService: LoaderService,
        private languageService: LanguageService,
        private apiUrlsService: ApiUrlsService,
    ) {
        this.initHeaders();
    }

    private initHeaders(): void {
        this.headers = new HttpHeaders().set('x-language', this.languageService.getLanguage());
        this.handleLanguageChanges();
    }

    @unsubscribeOnDestroy
    private handleLanguageChanges(): Subscription {
        return this.languageService.getCurrentLanguageChanges().subscribe((language) => {
            this.headers = this.headers.set('x-language', language);
        });
    }

    getVirtualIban(): Observable<VirtualIban> {
        if (this.virtualIban?.value || this.requesting) {
            return this.virtualIban.asObservable().pipe(filter<VirtualIban>(Boolean), distinctUntilChanged());
        }

        return this.requestVirtualIban().pipe(
            catchError((e) => {
                console.error(e);
                this.loaderService.set({
                    error: true,
                    title: 'unavailable',
                    text: null,
                    execute: of(EMPTY).pipe(
                        tap(() => {
                            this.store.state.update({ transferType: null });
                        }),
                    ),
                });
                return EMPTY;
            }),
        );
    }

    private requestVirtualIban(): Observable<VirtualIban> {
        this.requesting = true;
        const API_URLS = this.apiUrlsService.getUrls();

        return forkJoin([this.store.state.selectOnce('connectId'), this.psuService.get()]).pipe(
            switchMap(([connectId, psu]) =>
                this.http
                    .post<VirtualIban>(
                        `${API_URLS.virtual_iban_manual_payment_url}/${connectId}`,
                        {
                            ...(psu && psu?.completed && psu?.email && { psu }),
                        },
                        { headers: this.headers },
                    )
                    .pipe(
                        map((result) => new VirtualIban(result)),
                        tap((virtualIban) => this.virtualIban.next(virtualIban)),
                        finalize(() => (this.requesting = false)),
                    ),
            ),
        );
    }

    getCurrentIbanChangeDate(): Observable<string> {
        return this.virtualIban.asObservable().pipe(map((virtualIban: VirtualIban) => virtualIban?.ibanChangeDate));
    }
}
