import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EMPTY, map, Observable } from 'rxjs';

import { ApiUrlsService } from '@app-shared/constants';
import { LanguageService, StoreService } from '@app-shared/services';
import { AccountInformations, RefundSession, VerificationMatch } from '@app-shared/models';
import { PaymentType, Scope, SessionStatus } from '@app-shared/enums';
import { PaymentCallback } from '@payment/models';
import { RefundedPayment } from '@payout/models';
import { INCLUDE_DEVICE_FINGERPRINT } from '../../interceptors/device-fingerprint/device-fingerprint.interceptor';

@Injectable({
    providedIn: 'root',
})
export class CallbackService {
    constructor(private http: HttpClient, private store: StoreService, private languageService: LanguageService, private apiUrlsService: ApiUrlsService) {}

    initCallbackPis(params: Params): Observable<PaymentCallback> {
        const API_URLS = this.apiUrlsService.getUrls();
        const context = new HttpContext().set(INCLUDE_DEVICE_FINGERPRINT, true);
        return this.http
            .get(
                `${API_URLS.get_result_url}?${Object.entries(params)
                    .map((inputs: Array<string>) => inputs.join('='))
                    .join('&')}`,
                {
                    headers: new HttpHeaders().set('x-language', this.languageService.getLanguage()),
                    context,
                },
            )
            .pipe(map((result) => new PaymentCallback(result, params)));
    }

    createRefundSession(code: string, sessionId: string, customerId: string): Observable<RefundSession> {
        const API_URLS = this.apiUrlsService.getUrls();
        return this.http
            .post(`${API_URLS.refund_ais_session}`, {
                code,
                session_id: sessionId,
                customer_id: customerId,
            })
            .pipe(map((result) => new RefundSession(result)));
    }

    createRefund(accountId: string, sessionId: string): Observable<RefundSession> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http
            .post(API_URLS.psu_iban, {
                account_id: accountId,
                session_id: sessionId,
            })
            .pipe(map((result) => new RefundSession(result)));
    }

    getAccountInformations(sessionId: string): Observable<AccountInformations> {
        const API_URLS = this.apiUrlsService.getUrls();
        return this.http
            .get(API_URLS.account_information, {
                params: {
                    session_id: sessionId,
                },
            })
            .pipe(map((result) => new AccountInformations(result)));
    }

    getRefundedPayment(sessionId: string, scope: Scope[]): Observable<RefundedPayment> {
        const params = { scope };
        const API_URLS = this.apiUrlsService.getUrls();

        if (!sessionId) {
            console.error('Session_id unknown to get payment refund info');
            return EMPTY;
        }

        return this.http
            .get<RefundedPayment>(`${API_URLS.get_refunded_payment.replace('[[:sessionId]]', sessionId)}`, { params })
            .pipe(map((result) => new RefundedPayment(result)));
    }

    matchAccountVerification(connectionId: string, accountName: string): Observable<VerificationMatch> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http.post<VerificationMatch>(`${API_URLS.account_verification.replace('[[:connectionId]]', connectionId)}`, {
            account_name: accountName,
        });
    }

    unschedulePayment(sessionId: string, paymentType?: PaymentType): Observable<any> {
        const API_URLS = this.apiUrlsService.getUrls();

        if (!sessionId) {
            console.error('Session_id unknown for pps update');
            return EMPTY;
        }
        return this.http.patch(`${API_URLS.update_payment}/${sessionId}`, {
            paymentStatus: SessionStatus.PROVIDER_REQUIRED,
            paymentType: paymentType ?? PaymentType.REQUEST_TO_PAY,
            ...(paymentType !== PaymentType.RECOVERY && { unschedulePayment: true }),
        });
    }
}
