<ng-container *ngIf="data.loading">
    <div class="content">
        <ng-container *ngIf="isSpinnerShown">
            <fin-loader [diameter]="150"></fin-loader>
        </ng-container>

        <!-- Redirect to bank text -->
        <ng-container *ngIf="!data.error">
            <h2 class="fin-title-h2" *ngIf="data.bankName">
                {{ 'shared.loader.redirect.to-space' | translate : { providerName: data.bankName } }}
                <span *ngIf="beneficiary">{{ 'shared.loader.redirect.to-finalize-with' | translate : { beneficiary } }}</span>
            </h2>
            <h2 class="fin-title-h2" *ngIf="data.text">{{ data.text | translate }}</h2>
        </ng-container>

        <!-- Redirect error text -->
        <fin-illustration-action
            *ngIf="data.error"
            type="error"
            [titleText]="errorTitle | translate"
            [text]="errorText | translate: { bank: data.bankName }"
            [buttonText]="'shared.loader.error.retry' | translate"
            [supportText]="'shared.loader.error.contact-support' | translate"
            [isMobile]="isMobile"
            (buttonClicked)="reload()">
        </fin-illustration-action>
    </div>

    <app-footer *ngIf="!data.error"></app-footer>
</ng-container>
