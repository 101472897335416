import { DEFAULT_LANGUAGE } from '@app-shared/constants';
import { DateFormat } from '@app-shared/enums';

export function formatStringDate(stringDate: string | Date, lang: string = DEFAULT_LANGUAGE, format = DateFormat.MONTH_SHORT): string {
    const date = new Date(stringDate);
    if (date) {
        switch (format) {
            case DateFormat.NUMERIC_SHORT:
                // e.g. 12/01/2023
                return date.toLocaleString(lang, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            case DateFormat.MONTH_SHORT:
            default:
                // e.g. Jan. 12, 2023
                return date.toLocaleString(lang, {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                });
        }
    }

    return null;
}
