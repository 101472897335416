type Address = {
    number?: string;
    street?: string;
    complement?: string;
    zip?: string;
    city?: string;
    country?: string;
    longitude?: string;
    latitude?: string;
};

export class KyuCompanySearch {
    name: string;
    incorporation: string; // registration number
    address: Address;
    isDiffusible: boolean; // for BNPL - not eligible to BNPL if not diffusible

    constructor(obj: any) {
        this.name = obj?.data?.attributes?.name;
        this.incorporation = obj?.data?.attributes?.incorporation;
        this.address = obj?.data?.attributes?.address;
        this.isDiffusible = obj?.data?.attributes?.is_diffusible;
    }
}
