import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@app-shared/constants';

@Injectable({ providedIn: 'root' })
export class LanguageService {
    private supportedLanguages: string[] = SUPPORTED_LANGUAGES;
    private defaultLang = DEFAULT_LANGUAGE;
    private language: string;

    private currentLanguageChanges: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(@Inject(DOCUMENT) private document: Document, private translate: TranslateService) {}

    init(): void {
        this.translate.addLangs(this.supportedLanguages);
        this.translate.setDefaultLang(this.defaultLang);
        this.setLanguage(this.getBrowserLang());
    }

    setLanguage(language: string): void {
        const lang = this.supportedLanguages.includes(language) ? language : this.defaultLang;

        this.translate.use(lang);
        this.document.documentElement.lang = lang;
        this.language = lang;

        this.currentLanguageChanges.next(this.language);
    }

    getLanguage(): string {
        return this.supportedLanguages.includes(this.language) ? this.language : this.defaultLang;
    }

    getBrowserLang(): string {
        return this.translate.getBrowserLang();
    }

    getCurrentLanguageChanges(): Observable<string> {
        return this.currentLanguageChanges.asObservable();
    }
}
