export enum PaymentType {
    REQUEST_TO_PAY = 'RequestToPay',
    PAY_BY_BANK = 'PayByBank',
    REFUND = 'Refund',
    BUY_NOW_PAY_LATER = 'BuyNowPayLater',
    BUY_NOW_PAY_LATER_COLLECTOR = 'BuyNowPayLaterCollector',
    REQUEST_FOR_PAYOUT = 'RequestForPayout',
    RECOVERY = 'Recovery',
    RECONCILED_TRANSFER = 'ManualTransfer',
    DELAYED_PAYMENT = 'DelayedPayment',
}
