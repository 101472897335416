import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';

export class State<T> {
    private initialState: T;
    protected currentState: BehaviorSubject<T>;

    constructor(initialState: T) {
        this.initialState = initialState;
        this.currentState = new BehaviorSubject(initialState);
    }

    /**
     * Get the previous and current state, subscribe when value change
     * @returns Observable<T[]>
     */
    get(): Observable<T> {
        return this.currentState.asObservable();
    }

    /**
     * Get state synchronously
     * @returns T
     */
    get value(): T {
        return this.currentState.value;
    }

    /**
     * Get Once the current state
     * @returns Observable<T>
     */
    getOnce(): Observable<T> {
        return this.get().pipe(first());
    }

    /**
     * Select a value from the current state, subscribe when value change
     * @param {keyof T} key
     * @returns Observable<T>
     */
    select<K extends keyof T>(key: K): Observable<T[K]> {
        return this.get().pipe(
            map((state) => state[key]),
            distinctUntilChanged(),
        );
    }

    /**
     * Select Once a value from the current state
     * @param {keyof T} key
     * @returns Observable<T>
     */
    selectOnce<K extends keyof T>(key: K): Observable<T[K]> {
        return this.select(key).pipe(first());
    }

    update(nextState: Partial<T>): any {
        this.currentState.next({ ...this.currentState.value, ...nextState });
        return nextState;
    }

    reset(): void {
        this.currentState.next(this.initialState);
    }
}
