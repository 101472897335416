export enum BnplStatus {
    INIT = 'Init',
    PENDING = 'Pending',
    QUOTED = 'Quoted',
    VERIFIED = 'Verified',
    ACTIVATED = 'Activated',
    REJECTED = 'Rejected',
    REFUSED = 'Refused',
    SETTLED = 'Settled',
    ERROR = 'Error',
    PENDING_VERIFICATION = 'PendingVerification',
    CANCELLED = 'Cancelled',
    CANCELLED_VERIFICATION = 'CancelledVerification',
    SCA_REQUIRED = 'ScaRequired',
}
