import { Functionality } from '@app-shared/enums';

export function buildFunctionalities(obj: any): Functionality[] {
    if (!obj) {
        return [];
    }

    const functionalities: Functionality[] = Object.entries(obj)
        // filter raw object to extract activated Functionalities
        .filter(([key, value]) => {
            return Functionality[key.toUpperCase()] && value;
        })
        // Convert to Functionalities array
        .map(([key]) => {
            return Functionality[key.toUpperCase()];
        });

    return functionalities;
}
