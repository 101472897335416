import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreService } from '../store/store.service';
import { ApiUrlsService } from '@app-shared/constants';
import { Observable, Subscription, map, of, tap, timer } from 'rxjs';
import { CountryService } from '../country/country.service';
import { LanguageService } from '../language/language.service';
import { unsubscribeOnDestroy } from '@app-shared/decorators';
import { State } from '@app-shared/libs';
import { FormControl } from '@angular/forms';
import { OtpTemplate } from '@app-shared/enums';
import { OtpSession, OtpSessionResponse } from '@app-shared/models';
import { LoaderService } from '../loader/loader.service';

interface OtpState {
    codeSent: boolean;
    showCodeScreen: boolean;
    reachedMaxAttempts: boolean;
    codeExpired: boolean;
    sessionExpired: boolean;
    authTokenExpired: boolean;
    retry: boolean;
    attempts: number;
    confirmed: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class OtpService {
    private headers: HttpHeaders;
    readonly initialState: OtpState = {
        codeSent: false,
        showCodeScreen: false,
        reachedMaxAttempts: false,
        codeExpired: false,
        sessionExpired: false,
        authTokenExpired: false,
        retry: false,
        attempts: 0,
        confirmed: false,
    };

    private currentOtpSession: OtpSession;

    readonly state: State<OtpState> = new State<OtpState>(this.initialState);

    constructor(
        private http: HttpClient,
        private loaderService: LoaderService,
        private store: StoreService,
        private countryService: CountryService,
        private languageService: LanguageService,
        private apiUrlsService: ApiUrlsService,
    ) {
        this.handleStateChanges();
        this.handleCurrentSession();
        this.initHeaders();
    }

    private handleStateChanges(): void {
        this.state.get().subscribe((state) => {
            if (state.reachedMaxAttempts || state.codeExpired || state.sessionExpired || state.retry || state.authTokenExpired) {
                this.store.state.update({ otp: null });
            }
        });
    }

    private handleCurrentSession(): void {
        this.store.state.select('otp').subscribe((otp) => {
            this.currentOtpSession = otp ? new OtpSession(otp) : null;

            if (this.currentOtpSession) {
                this.state.update({ showCodeScreen: true });
            }

            if (this.currentOtpSession?.authToken) {
                timer(this.currentOtpSession.authToken.expiresIn).subscribe(() => this.state.update({ showCodeScreen: false, authTokenExpired: true }));
            }

            this.loaderService.set({ loading: false });
        });
    }

    private initHeaders(): void {
        this.headers = new HttpHeaders()
            .set('x-country', this.countryService.getCurrentCountry())
            .set('x-language', this.languageService.getLanguage())
            .set('connect_id', this.store.state.value.connectId);
        this.handleConnectId();
        this.handleLanguageChanges();
    }

    @unsubscribeOnDestroy
    private handleLanguageChanges(): Subscription {
        return this.languageService.getCurrentLanguageChanges().subscribe((language) => {
            this.headers = this.headers.set('x-language', language);
        });
    }

    @unsubscribeOnDestroy
    private handleConnectId(): Subscription {
        return this.store.state.select('connectId').subscribe((connectId) => {
            this.headers = this.headers.set('connect_id', connectId);
        });
    }

    getCurrentOtpSession(): OtpSession {
        return this.currentOtpSession;
    }

    postSessionOtp(template: OtpTemplate, tag?: string, force = false): Observable<OtpSession> {
        if (this.currentOtpSession && !force) {
            return of(this.currentOtpSession);
        }

        const sessionId = this.store.state.value.sessionId;
        const API_URLS = this.apiUrlsService.getUrls();
        return this.http
            .post<OtpSessionResponse>(
                `${API_URLS.sessions_otp.replace('[[:sessionId]]', sessionId)}`,
                {
                    template,
                    tag,
                },
                { headers: this.headers },
            )
            .pipe(
                map((otp) => {
                    otp.expire_at = new Date().getTime() + otp.expiration;
                    return otp;
                }),
                tap((otp) => {
                    this.state.update(this.initialState);
                    this.store.state.update({ otp: otp });
                }),
                map((response) => new OtpSession(response)),
            );
    }

    postSessionOtpVerification(code: string): Observable<string> {
        const sessionId = this.store.state.value.sessionId;
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http
            .post<string>(
                `${API_URLS.sessions_otp.replace('[[:sessionId]]', sessionId)}/verification`,
                {
                    code,
                },
                { headers: this.headers },
            )
            .pipe(
                tap((authToken: string) => {
                    if (typeof authToken === 'string' && this.currentOtpSession) {
                        this.currentOtpSession.setAuthToken(authToken);
                        this.store.state.update({ otp: this.currentOtpSession.sessionResponse });
                    }
                }),
            );
    }

    /**
     * Custom validator to check the format of the code provided by PSU (must be 6 digits long)
     */
    codeValidator(control: FormControl): { 'otp-format': boolean } | null {
        // at least 6 digits and up to 12 digits so we don't leak OTP code strength
        const otpCodeRegex = new RegExp(/^[0-9]{6}$/);
        if (!otpCodeRegex.test(control?.value)) {
            return { 'otp-format': true };
        }
        return null;
    }
}
