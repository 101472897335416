import { JsonWebToken } from './json-web-token.model';

export interface OtpSessionResponse {
    id: string;
    expiration: number;
    max_attempts: number;
    session_id: string;
    expire_at: number;
    auth_token?: string;
}

export class OtpSession {
    id: string;
    expiration: number;
    maxAttempts: number;
    sessionId: string;
    expireAt: number;
    authToken?: JsonWebToken;

    constructor(obj: OtpSessionResponse) {
        this.expiration = obj.expiration;
        this.id = obj.id;
        this.maxAttempts = obj.max_attempts;
        this.sessionId = obj.session_id;
        this.expireAt = obj.expire_at;
        this.authToken = obj.auth_token ? new JsonWebToken(obj.auth_token) : null;
    }

    setAuthToken(token: string): void {
        this.authToken = new JsonWebToken(token);
    }

    get sessionResponse(): OtpSessionResponse {
        return {
            expiration: this.expiration,
            id: this.id,
            max_attempts: this.maxAttempts,
            session_id: this.sessionId,
            expire_at: this.expireAt,
            auth_token: this.authToken?.toString(),
        };
    }
}
