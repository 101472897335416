export class VirtualIban {
    bankName: string;
    virtualIbanId: string;
    externalIbanId: string;
    key: string;
    status: 'enabled' | 'disabled';
    currency: string;
    iban: string;
    bic: string;
    blockSdd: string;
    ibanChangeDate: string;

    constructor(obj: any) {
        this.ibanChangeDate = obj.iban_change_date;
        this.bankName = obj.bank_name;
        this.virtualIbanId = obj.virtual_iban_id;
        this.externalIbanId = obj.external_iban_id;
        this.key = obj.key;
        this.status = obj.status;
        this.currency = obj.currency;
        this.iban = obj.iban;
        this.bic = obj.bic;
        this.blockSdd = obj.block_sdd;
    }
}
