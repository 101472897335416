import { Injectable } from '@angular/core';
import { ChildrenProviders } from '../provider/provider.service';
import { Scope } from '@app-shared/enums';
import { Provider } from '@app-shared/models';
import fakeProviders from '@assets/providers/providers-not-implemented.json';

@Injectable({ providedIn: 'root' })
export class FakeProviderService {
    private fakeProviders: Array<ChildrenProviders> = this.getFakeProviders();

    getProvidersListWithFakeProviders(country: string, scope: Scope, providers: Array<ChildrenProviders>): Array<ChildrenProviders> {
        const hasFakeProviders = scope === Scope.PIS && country === 'fr';
        if (!hasFakeProviders) {
            return providers;
        }

        const fullProvidersList = this.appendProvidersWithoutDuplicates(providers, this.fakeProviders);
        return fullProvidersList;
    }

    private getFakeProviders(): Array<ChildrenProviders> {
        // Load JSON file containing fake providers
        return Object.values(fakeProviders.providers).map((provider) => [new Provider(provider[0])]);
    }

    /**
     * Append fakeProviders to providers after validating that they are not already in the list
     */
    private appendProvidersWithoutDuplicates(providers: Array<ChildrenProviders>, fakeProviders: Array<ChildrenProviders>): Array<ChildrenProviders> {
        if (!fakeProviders?.length) {
            return providers;
        }

        const existingMnemonics = new Set(providers.flat().map((child) => child?.id));
        return [...providers, ...fakeProviders.filter((provider) => !existingMnemonics.has(provider[0]?.id))];
    }
}
