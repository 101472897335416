import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AbTestService, AbTestPlanResponse } from '@fintecture-npm/angular-ui';
import { HttpClient } from '@angular/common/http';
import { ApiUrlsService } from '@app-shared/constants';
import { Observable, catchError, of, timeout } from 'rxjs';

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (abTestService: AbTestService, http: HttpClient, apiUrlsService: ApiUrlsService) => {
                return (): Observable<AbTestPlanResponse> => {
                    // As the initializer runs before the router, we need to get the connectId/sessionId from the url, accessing directly the window object
                    const connectIdUuidRegex = /\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/;
                    const sessionIdRegex = /session_id=[0-9a-f]{32}/;
                    const API_URLS = apiUrlsService.getUrls();
                    const id: string =
                        window.location.href.match(connectIdUuidRegex)?.[0]?.substring(1) || window.location.href.match(sessionIdRegex)?.[0]?.split('=')?.[1];
                    const abTestGet = id
                        ? http.get<AbTestPlanResponse>(`${API_URLS.get_ab_tests}`.replace('[[:id]]', id)).pipe(
                              catchError((err) => of(err)),
                              timeout(500),
                          )
                        : of(null);
                    return abTestService.init(abTestGet).pipe(catchError((err) => of(err)));
                };
            },
            deps: [AbTestService, HttpClient, ApiUrlsService],
        },
    ],
})
export class InitializerModule {}
