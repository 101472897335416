import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

type RecursiveArray = Array<RecursiveArray | any>;

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private searchStringValue: string;
    private searchStringChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly resetSearchEvent: EventEmitter<void> = new EventEmitter();

    public set searchString(searchString: string) {
        searchString = searchString?.trim();
        if (searchString !== this.searchStringValue) {
            this.searchStringValue = searchString;
            this.searchStringChanged.next(this.searchStringValue);
        }
    }

    public get searchString(): string {
        return this.searchStringValue;
    }

    public getSearchStringChanges(): Observable<string> {
        return this.searchStringChanged.asObservable();
    }

    public resetSearch(): void {
        this.searchString = '';
        this.resetSearchEvent.next();
    }

    public get resetEvent(): Observable<void> {
        return this.resetSearchEvent.asObservable();
    }

    /**
     * Returns true if the searchString match with the key
     * @param {string} key
     * @param {string} searchString
     * @returns {boolean}
     */
    static matchString(key: string, searchString: string): boolean {
        return SearchService.formatResearch(key).includes(SearchService.formatResearch(searchString));
    }

    /**
     * Find a value by property in an array of objects
     * @param {RecursiveArray} values Recursive array of any objects
     * @param {string} searchString
     * @param {string} property
     * @returns
     */
    static filterMultiArrayByProperty(values: RecursiveArray, searchString: string, property: string): any {
        const result = [];

        for (const subValues of values) {
            if (Array.isArray(subValues)) {
                const output = SearchService.filterMultiArrayByProperty(subValues, searchString, property);
                if (output?.length) {
                    result.push(output);
                }
            } else {
                const filter = SearchService.formatResearch(subValues[property]).includes(SearchService.formatResearch(searchString));
                if (filter) {
                    result.push(subValues);
                }
            }
        }

        return result;
    }

    static formatResearch(searchString: string): string {
        return searchString
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[^a-z0-9+]+/gi, '');
    }
}
