export enum SessionStatus {
    SESSION_INITIATED = 'session_initiated',
    PROVIDER_REQUIRED = 'provider_required',
    SCA_REQUIRED = 'sca_required',
    CONFIRMATION_REQUIRED = 'confirmation_required',
    PAYMENT_CREATED = 'payment_created',
    PAYMENT_UNSUCCESSFUL = 'payment_unsuccessful',
    PAYMENT_PENDING = 'payment_pending',
    PAYMENT_ERROR = 'payment_error',
    PAYMENT_UNKNOWN = 'payment_unknown',
    PAYMENT_CANCELLED = 'payment_cancelled',
    PAYMENT_PARTIAL = 'payment_partial',
    PAYMENT_EXPIRED = 'payment_expired',
    IBAN_REQUIRED = 'iban_required', // used for request-for-refund
    IBAN_RECEIVED = 'iban_received', // used for request-for-refund
    ORDER_CREATED = 'order_created',
}
