import { OtpTransportMethod } from '@app-shared/enums';
import { VerificationWording } from './verification-wording.model';

export interface OtpConfig {
    method: OtpTransportMethod; // code sent via email or sms
    requestOtpOnInit: boolean; // generate & send a fresh code when component loaded
    redirectExpiredLink: boolean; // make the component redirect to expired-link page when error OTP_SESSION_EXCEEDED_CODE_LIMIT encountered
    validityDuration: number; // max duration (minutes) before code expires
    showCancel?: boolean; // show Cancel button on component
    translations?: VerificationWording; // translation keys to display
}
