import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentType, RefundStatus, Scope, SessionStatus } from '@app-shared/enums';
import { Application, BnplPaymentSession, Connect, Onboarding, Payment, Session } from '@app-shared/models';
import { CountryService, LanguageService, RedirectService } from '@app-shared/services';
import { EMPTY, Observable, catchError, map, of, tap } from 'rxjs';
import { ApiUrlsService } from '@app-shared/constants';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { StoreService } from '../../../../shared/services/store/store.service';
import { INCLUDE_DEVICE_FINGERPRINT } from '@app-shared/interceptors/device-fingerprint/device-fingerprint.interceptor';
import { paymentHasRedirection } from '@app-shared/helpers/payment-has-redirection.helper';

@Injectable({
    providedIn: 'root',
})
export class ConnectService {
    private _connect: Connect;
    private _bnplPaymentSession: BnplPaymentSession;

    private paymentDetails: Payment;
    private sessionDetails: Session;
    private application?: Application;
    private onboarding?: Onboarding;
    private fromEmail: boolean;

    constructor(
        private http: HttpClient,
        private router: Router,
        private countryService: CountryService,
        private languageService: LanguageService,
        private store: StoreService,
        private activatedRoute: ActivatedRoute,
        private redirectService: RedirectService,
        private apiUrlsService: ApiUrlsService,
    ) {}

    initConnect(connectId: string): Observable<Connect> {
        let headers = new HttpHeaders().set('x-country', this.countryService.getCurrentCountry()).set('x-language', this.languageService.getLanguage());

        const authToken = this.store.state.value.otp?.auth_token;

        if (authToken) {
            headers = headers.set('authorization', `Bearer ${authToken.toString()}`);
        }

        const context = new HttpContext().set(INCLUDE_DEVICE_FINGERPRINT, true);
        const API_URLS = this.apiUrlsService.getUrls();
        return this.http.get(`${API_URLS.init_connect_url}/${connectId}`, { headers, context }).pipe(
            map((result) => new Connect(result)),
            tap((session) => (this._connect = session)),
            catchError((err) => {
                this.router.navigate(['/v2/error'], {
                    queryParams: err.error,
                });

                return EMPTY;
            }),
        );
    }

    initBnplCallback(connectId: string): Observable<BnplPaymentSession> {
        const API_URLS = this.apiUrlsService.getUrls();
        return this.http.get(`${API_URLS.get_bnpl_session}/${connectId}`).pipe(
            map((result) => new BnplPaymentSession(result)),
            tap((session) => (this._bnplPaymentSession = session)),
            catchError((err) => {
                const id = err?.error?.connectId || connectId;
                if (id) {
                    this.router.navigate(['/v2/' + id]);
                } else {
                    this.router.navigate(['/v2/error']);
                }
                return EMPTY;
            }),
        );
    }

    postSurveyRating(survey: { rating: number; psuComment?: string }, connectId: string): Observable<void> {
        const body = { rating: survey.rating, psu_comment: survey.psuComment };
        const headers = new HttpHeaders().set('connect_id', connectId);
        const API_URLS = this.apiUrlsService.getUrls();
        return this.http.post(`${API_URLS.post_survey_rating}`, body, { headers }).pipe(catchError((err) => of(err)));
    }

    get(): Connect {
        return this._connect;
    }

    getBnplPaymentSession(): BnplPaymentSession {
        return this._bnplPaymentSession;
    }

    ruleOfScope(connect?: Connect, fromEmail = false): void {
        this.paymentDetails = connect?.paymentDetails;
        this.sessionDetails = connect?.sessionDetails;
        this.application = connect?.application;
        this.onboarding = connect?.onboarding;

        this.fromEmail = fromEmail;

        // Cancelled not from mail and expired by date are redirected
        if ((connect?.paymentDetails?.isCancelled() && !connect?.paymentDetails?.validReconciledTransfer()) || connect.expiryDate) {
            this.store.state.update({ commercialName: connect?.application?.commercialName });
            this.router.navigate(['/callback/expired-session']);
            return;
        }

        switch (connect?.sessionDetails?.scope) {
            case 'ais':
                this.ais();
                break;
            case 'pis':
                this.pis();
                break;
            case 'ocr':
                this.ocr();
                break;
            default:
                break;
        }
    }

    pis(paymentDetails = this.paymentDetails): void {
        if (this._connect?.settings?.stRedesign && paymentDetails?.providerPaymentType === PaymentType.RECONCILED_TRANSFER) {
            // No need to redirect to callback page if coming from email or if no redirect URL on connect_session
            if (!this.paymentHasRedirection() || this.fromEmail) {
                return;
            }
        }

        if (
            paymentDetails?.isAccepted ||
            paymentDetails?.isCancelled() ||
            (paymentDetails?.status === SessionStatus.PAYMENT_UNSUCCESSFUL && (!this.application?.allowRetryOnFailure || !paymentDetails?.isRetryable))
        ) {
            this.router.navigate(['/v2/payment/callback'], {
                queryParams: {
                    provider: paymentDetails.provider,
                    session_id: paymentDetails.sessionId,
                    fm: this.fromEmail || null,
                },
            });
            return;
        }
    }

    paymentHasRedirection(): boolean {
        return paymentHasRedirection(this.sessionDetails?.redirectUrl, this.application, this.paymentDetails, this.activatedRoute);
    }

    private ais(): void {
        const isRequestForPayout = this.paymentDetails?.providerPaymentType === PaymentType.REQUEST_FOR_PAYOUT;
        const isRefundInitiated = this.paymentDetails?.refundStatus === RefundStatus.REFUND_INITIATED;
        const isPaymentIbanRequired = this.paymentDetails?.status === SessionStatus.IBAN_REQUIRED;
        // Only for ais refund & payout
        if ((this.paymentDetails && !isRefundInitiated && !isRequestForPayout) || (!isPaymentIbanRequired && isRequestForPayout)) {
            this.router.navigate(['/v2/payout/callback'], {
                queryParams: {
                    state: this.sessionDetails?.sessionId,
                },
            });
            return;
        }

        // Redirect to onboarding if the onboarding is finished
        if (this.onboarding?.relatedRedirectLink) {
            this.redirectService.redirect(this.onboarding?.relatedRedirectLink).catch((e) => console.error(e));
            return;
        }
    }

    private ocr(): void {
        if (this.paymentDetails?.status && !['iban_required', 'refund_initiated'].includes(this.paymentDetails?.status)) {
            this.router.navigate(['/v2/payout/callback'], {
                queryParams: {
                    scope: Scope.OCR,
                    sessionId: this.sessionDetails?.sessionId,
                    id: this.sessionDetails?.connectId,
                },
            });
            return;
        }
    }
}
