import { Injectable, Inject, Component, HostListener } from '@angular/core';
import { LanguageService, LoaderService, StoreService } from '@app-shared/services';
import { APP_CONFIG, AppConfig } from '../app.config';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
@Injectable()
export class AppComponent {
    envName: string;
    constructor(
        private language: LanguageService,
        private store: StoreService,
        private loaderService: LoaderService,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {
        this.language.init();
        this.envName = this.config.env_name;
    }

    /**
     * On before unload, save the storage to the session,
     * Also avoid session modification for security
     */
    @HostListener('window:beforeunload') saveState(): void {
        this.store.saveState();
    }

    @HostListener('window:pageshow') onBackFromBank(): void {
        // If we come back an external url
        if (this.loaderService.getLoaderState().leaveApplication) {
            window.location.reload();
        }
    }
}
