import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Origin } from '@app-shared/enums';

export function getOrigin(route: ActivatedRouteSnapshot): Origin {
    if (route.queryParams?.fm === 'true') {
        return Origin.EMAIL;
    }

    return Origin.UNKNOWN;
}

/**
 * Performs a redirect to same current page and deletes the provided query params from URL
 * @param params query params to delete from URL (string or string[])
 */
export function removeQueryParam(router: Router, activatedRoute: ActivatedRoute, params: string | string[]): void {
    // Get the current query parameters
    const queryParams = { ...activatedRoute.snapshot.queryParams };

    // Remove the specified query parameter
    if (Array.isArray(params)) {
        for (const param of params) {
            delete queryParams[param];
        }
    } else {
        delete queryParams[params];
    }

    // Navigate to the same route with the updated query parameters
    router.navigate([], {
        relativeTo: activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: '', // preserve the remaining parameters
        skipLocationChange: false, // to ensure URL is updated in browser
    });
}
