export enum DataCollectingEvent {
    PIS_BANK_SCREEN_CHANGE_PROVIDER = 'pis_bank_screen_change_provider',
    PIS_BANK_SEARCH_UPDATE_SMART_TRANSFER = 'pis_bank_search_update_smart_transfer',
    PIS_BANK_SEARCH_FALLBACK_SMART_TRANSFER = 'pis_bank_search_fallback_smart_transfer',
    PIS_BANK_DISABLED_FALLBACK_SMART_TRANSFER = 'pis_bank_disabled_fallback_smart_transfer',
    PIS_BANK_SELECTED = 'pis_bank_selected',
    PAYMENT_METHOD_SELECTOR_BACK = 'payment_method_selector_back',
    PAYMENT_METHOD_SELECTOR_PAYBYBANK = 'payment_method_selector_paybybank',
    PAYMENT_METHOD_SELECTOR_MANUAL_TRANSFER = 'payment_method_selector_manual_transfer',
    PIS_PAYMENT_PENDING_EMAIL_TRACKER = 'pis_payment_pending_email_tracker',
    PIS_PAYMENT_PENDING_REFRESH = 'pis_payment_pending_refresh',
    PIS_BANK_SCREEN_FAVORITE = 'pis_bank_screen_favorite',
    CONNECT_SESSION_CANCELLED = 'connect_session_cancelled',
    CONNECT_REDIRECTION_INITIATED = 'connect_redirection_initiated',
    CONNECT_REDIRECTION_DELAYED = 'connect_redirection_delayed',
    CONNECT_CLOSED = 'connect_closed',
    PIS_DEBTOR_ACCOUNT_IBAN_FORM = 'pis_debtor_account_iban_form',
    PIS_FAQ_LINK_NEW_PAYER = 'pis_faq_link_new_payer',
    PIS_BANK_SCREEN_BANK_HELPER_LINK = 'pis_bank_screen_bank_helper_link',
    PIS_BANK_SCREEN_FAVORITE_SURVEY_SHOWN = 'pis_bank_screen_favorite_survey_shown',
    PIS_PAYMENT_FALLBACK_PAYMENT_METHOD_SELECTOR = 'pis_payment_fallback_payment_method_selector',
    PIS_PAYMENT_FALLBACK_BANK_SEARCH = 'pis_payment_fallback_bank_search',
    // Smart Transfer
    MANUAL_TRANSFER_DOWNLOAD_PAYMENT_INFO = 'manual_transfer_download_payment_info',
    MANUAL_TRANSFER_BANK_INFO_SCREEN = 'manual_transfer_bank_info_screen',
    MANUAL_TRANSFER_PAYMENT_PARTIAL_IBAN_SHOWN = 'manual_transfer_payment_partial_iban_shown',
    MANUAL_TRANSFER_IBAN_COPY_BENEFICIARY = 'manual_transfer_iban_copy_beneficiary',
    MANUAL_TRANSFER_IBAN_COPY_IBAN = 'manual_transfer_iban_copy_iban',
    MANUAL_TRANSFER_IBAN_COPY_BIC = 'manual_transfer_iban_copy_bic',
    MANUAL_TRANSFER_IBAN_COPY_REFERENCE = 'manual_transfer_iban_copy_reference',
    MANUAL_TRANSFER_IBAN_COPY_AMOUNT = 'manual_transfer_iban_copy_amount',
    MANUAL_TRANSFER_BANK_INFO_STEP_1 = 'manual_transfer_bank_info_step_1',
    MANUAL_TRANSFER_BANK_INFO_STEP_2 = 'manual_transfer_bank_info_step_2',
    MANUAL_TRANSFER_PAYMENT_COMPLETED = 'manual_transfer_payment_completed',
    // Web2App
    PIS_BANK_SCREEN_WEB2APP_QR_SHOWN = 'pis_bank_screen_web2app_qr_shown',
    PIS_BANK_SCREEN_WEB2APP_QR_SCANNED = 'pis_bank_screen_web2app_qr_scanned',
    PIS_WEB2APP_REDIRECT_BANK_AREA = 'pis_web2app_redirect_bank_area',
    PIS_WEB2APP_HELPER_LINK = 'pis_web2app_helper_link',
    PIS_WEB2APP_BACK_TO_BANK_LIST = 'pis_web2app_back_to_bank_list',
    // Chatbot helper
    PIS_CHATBOT_HELPER_SHOWN = 'pis_chatbot_helper_shown',
    PIS_CHATBOT_HELPER_CLOSED = 'pis_chatbot_helper_closed',
    PIS_CHATBOT_HELPER_CLICKED = 'pis_chatbot_helper_clicked',
    // Coming from email
    EMAIL_SMART_TRANSFER_GET_PAID_CTA_CLICKED = 'email_smartTransferGetPaid_cta_clicked',
    EMAIL_SMART_TRANSFER_GET_PAID_HUB_CTA_CLICKED = 'email_smartTransferGetPaidHub_cta_clicked',
    EMAIL_SMART_TRANSFER_IBAN_REVEALED_CTA_CLICKED = 'email_smartTransferIbanRevealed_cta_clicked',
    EMAIL_MANUAL_TRANSFER_TRACKER_CLICKED = 'email_manualTransfer_tracker_clicked',
    EMAIL_PSU_ENQUIRY_PAYMENT_NOT_ARRIVED_CTA_CLICKED = 'email_psuEnquiryPaymentNotArrived_cta_clicked',
    EMAIL_PSU_RECOVERY_TRUSTED_CTA_CLICKED = 'email_psuRecovery_trusted_cta_clicked',
    // Cancel links events
    PIS_BANK_SCREEN_CANCEL_LINK = 'pis_bank_screen_cancel_link',
    PIS_BANK_SEARCH_CANCEL_LINK = 'pis_bank_search_cancel_link',
    PIS_PAYMENT_FALLBACK_CANCEL_LINK = 'pis_payment_fallback_cancel_link',
    PIS_PAYMENT_METHOD_SELECTOR_CANCEL_LINK = 'pis_payment_method_selector_cancel_link',
    PIS_MANUAL_TRANSFER_CANCEL_LINK = 'pis_manual_transfer_cancel_link',
    PIS_BANK_SEARCH_CANCEL_MODAL_CONFIRM = 'pis_bank_search_cancel_modal_confirm',
    PIS_BANK_SEARCH_CANCEL_MODAL_FALLBACK_SMART_TRANSFER = 'pis_bank_search_cancel_modal_fallback_smart_transfer',
    PIS_BANK_SEARCH_CANCEL_MODAL_CLOSED = 'pis_bank_search_cancel_modal_closed',
    BNPL_CANCELLED = 'bnpl_cancelled',
}
