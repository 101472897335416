// src/app/services/fingerprint.service.ts
import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../../app.config';
import { Observable, catchError, of, shareReplay, timeout } from 'rxjs';
import * as Castle from '@castleio/castle-js';

const INDIVIDUAL_REQUEST_DEVICE_FINGERPINT_TIMEOUT = 3000;
const INIT_DEVICE_FINGERPINT_TIMEOUT = 10000;

@Injectable({
    providedIn: 'root',
})
export class FingerprintService {
    private deviceFingerprint$: Observable<string | null>;

    constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

    getFingerprintData(): Observable<string | null> {
        if (!this.deviceFingerprint$) {
            return of(null);
        }
        return this.deviceFingerprint$.pipe(
            timeout(INDIVIDUAL_REQUEST_DEVICE_FINGERPINT_TIMEOUT), // short await in every request for fingerprint data
            catchError(() => of(null)),
        );
    }

    initDeviceFingerprint(): void {
        if (!this.config.castleIO) {
            this.deviceFingerprint$ = of(null);
            return;
        }

        const CastleInitializer: Observable<string> = new Observable((observer) => {
            try {
                const castle = Castle.configure({ pk: this.config.castleIO });
                castle.createRequestToken().then((requestToken) => {
                    observer.next(requestToken);
                    observer.complete();
                });
            } catch (error) {
                observer.error(error);
            }
        });
        this.deviceFingerprint$ = CastleInitializer.pipe(
            timeout(INIT_DEVICE_FINGERPINT_TIMEOUT), // longer await for init call
            catchError(() => of(null)),
            shareReplay(),
        );
    }
}
