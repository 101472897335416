import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlsService } from '@app-shared/constants';
import { DataCollectingEvent } from '@app-shared/enums';
import { Observable, catchError, of } from 'rxjs';
import { StoreService } from '../store/store.service';

interface DataCollectingOptions {
    connectId?: string;
    beacon?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class DataCollectingService {
    constructor(private http: HttpClient, private store: StoreService, private apiUrlsService: ApiUrlsService) {}

    sendEvent(event: DataCollectingEvent, options?: DataCollectingOptions): Observable<void> {
        const connectId = options?.connectId || this.store.state.value.connectId;

        if (options?.beacon) {
            return this.beaconEvent(event, connectId);
        }

        return this.httpEvent(event, connectId);
    }

    private httpEvent(event: DataCollectingEvent, connectId: string): Observable<void> {
        const API_URLS = this.apiUrlsService.getUrls();
        return this.http
            .post(API_URLS.event_collection.replace('[[:connectId]]', connectId).replace('[[:event]]', event), {})
            .pipe(catchError((err) => of(err)));
    }

    private beaconEvent(event: DataCollectingEvent, connectId: string): Observable<void> {
        const API_URLS = this.apiUrlsService.getUrls();

        navigator.sendBeacon(API_URLS.event_collection.replace('[[:connectId]]', connectId).replace('[[:event]]', event));
        return of();
    }
}
