import { Injectable } from '@angular/core';
import { first, map, Observable, of, tap } from 'rxjs';

import { Beneficiary, BnplPaymentSession, Connect } from '@app-shared/models';
import { StoreService } from '../store/store.service';
import { PaymentCallback } from '@payment/models';

@Injectable({
    providedIn: 'root',
})
export class BeneficiaryService {
    constructor(private store: StoreService) {}

    get(): Observable<Beneficiary> {
        return this.store.state.select('beneficiary');
    }

    init(beneficiary: Beneficiary): Observable<Beneficiary> {
        return this.get().pipe(
            first(),
            map((storeBeneficiary: Beneficiary) => {
                let filledBeneficiary;

                if (beneficiary) {
                    filledBeneficiary = beneficiary.merge(storeBeneficiary);
                } else {
                    filledBeneficiary = storeBeneficiary;
                }

                this.store.state.update({
                    beneficiary: filledBeneficiary,
                });

                return filledBeneficiary;
            }),
        );
    }

    initFromResolver(resolver: Connect | PaymentCallback): Observable<Connect | PaymentCallback | BnplPaymentSession> {
        if (!resolver.paymentDetails) {
            return of(resolver);
        }

        if (!resolver.paymentDetails?.shouldHaveBeneficiary()) {
            this.store.state.update({ beneficiary: resolver.paymentDetails?.beneficiary });
            return of(resolver);
        }

        return this.init(resolver.paymentDetails.beneficiary).pipe(
            tap((beneficiary: Beneficiary) => (resolver.paymentDetails.beneficiary = beneficiary)),
            map(() => resolver),
        );
    }
}
