import { Address } from './address.model';

export class PSUCompanyInfo {
    companyName: string;
    address: Address;
    companyRegistrationNumber: string;

    constructor(obj: any) {
        this.companyName = obj.companyName;
        this.address = obj.address;
        this.companyRegistrationNumber = obj.companyRegistrationNumber;
    }
}
