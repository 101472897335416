import { checkURL } from '@app-shared/helpers';
import { Functionality, Scope } from '../enums';
import { CountryInfo } from './country-info.model';

export class Session {
    companyId: string;
    connectId: string;
    sessionId?: string;
    country: string;
    language: string;
    originUri: URL;
    provider: string;
    redirectUrl: string;
    scope: Scope;
    state: string;
    countryInfo?: CountryInfo;
    functionalities: Functionality[];

    constructor(obj: any) {
        this.companyId = obj.company_id;
        this.connectId = obj.connect_id;
        this.sessionId = obj.session_id;
        this.country = obj.country;
        this.language = obj.language;

        if (obj.origin_uri && checkURL(obj.origin_uri)) {
            this.originUri = new URL(obj.origin_uri);
        }
        this.provider = obj.provider;
        this.redirectUrl = obj.redirect_url;
        this.scope = obj.scope;
        this.state = obj.state;
        this.countryInfo = obj.country_info ? new CountryInfo(obj.country_info) : undefined;
    }

    isPis(): boolean {
        return this.scope === Scope.PIS;
    }

    isBnpl(): boolean {
        return this.scope === Scope.BNPL;
    }

    isAis(): boolean {
        return this.scope === Scope.AIS;
    }
}
