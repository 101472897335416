import { Component, OnInit } from '@angular/core';
import { LoaderService, RedirectService } from '@app-shared/services';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-error-page',
    templateUrl: './not-found.page.html',
    styleUrls: ['./not-found.page.scss'],
})
export class NotFoundPage implements OnInit {
    private payersUrl = 'https://www.fintecture.com/payers/';
    isMobile = this.deviceService.isMobile();

    constructor(private loaderService: LoaderService, private redirectService: RedirectService, private deviceService: DeviceDetectorService) {}

    ngOnInit(): void {
        this.loaderService.set({ loading: false });
    }

    redirect(): void {
        this.redirectService.redirect(this.payersUrl);
    }
}
