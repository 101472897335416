export class Quote {
    quoteId: string;
    currency: string;
    state: string;
    coveredAmount: number;

    constructor(obj: any) {
        this.quoteId = obj.quote_id;
        this.currency = obj.currency;
        this.state = obj.state;
        this.coveredAmount = obj.covered_amount;
    }
}
