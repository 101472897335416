type KyuUser = {
    id: string;
    type: 'users';
};

type KyuBankAccount = {
    id: string;
    type: 'bank_accounts';
};

export class KyuCompany {
    id: string;
    name: string;
    fullName: string;
    incorporation: string; // registration number
    users: string[];
    bankAccounts: string[];

    constructor(obj: any) {
        this.id = obj?.data?.id;
        this.name = obj?.data?.attributes?.name;
        this.fullName = obj?.data?.attributes?.full_name;
        this.incorporation = obj?.data?.attributes?.incorporation;
        this.users = (obj?.data?.relationships?.users?.data || []).map((user: KyuUser) => user?.id);
        this.bankAccounts = (obj?.data?.relationships?.bank_accounts?.data || []).map((bankAccount: KyuBankAccount) => bankAccount?.id);
    }
}
