import { BnplStatus } from '../enums';

export class BnplSession {
    paymentType: string;
    state: BnplStatus;
    pisFallbackLink: string;
    isSDDFlowEnabled?: boolean;

    constructor(obj: any) {
        this.paymentType = obj.payment_type;
        this.state = obj.state;
        this.pisFallbackLink = obj.pis_fallback_link;
        this.isSDDFlowEnabled = obj.is_sdd_enabled || false;
    }
}
