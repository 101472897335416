import { Injectable } from '@angular/core';

type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

@Injectable({ providedIn: 'root' })
export class FileService {
    saveFile(name: string, type: string, data: Blob, target: LinkTarget): void {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([data], { type: type }));
        a.setAttribute('href', url);
        a.setAttribute('download', name);
        if (target) {
            a.setAttribute('target', target);
        }
        document.body.append(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
