import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-provider-list-skeleton-loader',
    templateUrl: './provider-list-skeleton-loader.component.html',
    styleUrls: ['./provider-list-skeleton-loader.component.scss'],
})
export class ProviderListSkeletonLoaderComponent implements OnInit {
    @Input() displayRow: boolean;

    provider: Partial<CSSStyleDeclaration>;

    ngOnInit(): void {
        this.provider = {
            borderRadius: '8px',
            width: '100%',
            height: this.displayRow ? '60px' : '120px',
            margin: '0',
        };
    }
}
