import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ErrorService {
    errorObject = {
        status: 404,
        text: 'Page not found',
    };

    errorHandler(err): void {
        this.errorObject.status = err.status || this.errorObject.status;
        this.errorObject.text = err.details || this.errorObject.text;
    }

    navigate2Error(err, router): void {
        this.errorHandler(err);
        router.navigate(['v2/error']);
    }
}
