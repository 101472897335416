import { PisAccountSelection, AccountIdentifier } from '@app-shared/enums';

const AMOUNTS_MAPPING = [
    ['minAmount', 'min_amount'],
    ['maxAmount', 'max_amount'],
    ['isctMinAmount', 'isct_min_amount'],
    ['isctMaxAmount', 'isct_max_amount'],
] as const;

export class Provider {
    id: string;
    technicalServiceProviderId: string;
    name: string;
    fullName: string;
    hasAppToApp: boolean;
    logoUrl: string;
    parent: string;
    parentFullName: string;
    minAmount: string;
    minAmountNumber: number;
    maxAmount: string;
    maxAmountNumber: number;
    isctMinAmount: string;
    isctMinAmountNumber: number;
    isctMaxAmount: string;
    isctMaxAmountNumber: number;
    hasScheduledPayments: boolean;
    hasPaymentsInstantSepa: boolean;
    isAvailable: boolean;
    hasInstFeeRefunds: boolean;
    isctFeePolicy: 'FREE' | null | string;
    hasAccountHolders: boolean;
    pisAccountSelection: PisAccountSelection;
    accountIdentifier: AccountIdentifier;
    swiftBic: string;
    isWeb2appEnabled: boolean;
    isFakeProvider?: boolean;
    uiConfig?: {
        fontTone?: 'light' | 'dark';
        gradient?: string;
        primaryColor?: string;
        secondaryColor?: string;
        authConfig?: {
            username_type: number;
            password_type: number;
            username_constraint?: string;
            password_constraint?: string;
            username_length?: number;
            username_min_length?: number;
            username_max_length?: number;
            password_length?: number;
            login_faq_url?: string;
        };
    };

    constructor(obj: any) {
        this.id = obj.id;
        this.technicalServiceProviderId = obj.technical_service_provider_id;
        this.name = obj.name;
        this.fullName = obj.full_name;
        this.hasAppToApp = obj.has_app_to_app;
        this.logoUrl = obj.logo_url;
        this.parent = obj.parent;
        this.parentFullName = obj.parent_full_name;
        AMOUNTS_MAPPING.forEach(([localKey, objKey]) => {
            if (obj[objKey]) {
                this[localKey] = obj[objKey].replace(',', '.');
                this[`${localKey}Number`] = parseFloat(this[localKey]);
            }
        });
        this.hasScheduledPayments = obj.has_scheduled_payments;
        this.hasPaymentsInstantSepa = obj.has_payments_instant_sepa;
        this.isAvailable = obj.is_available;
        this.hasInstFeeRefunds = obj.has_inst_fee_refunds;
        this.isctFeePolicy = obj.isct_fee_policy;
        this.hasAccountHolders = obj.has_account_holders;
        this.pisAccountSelection = obj.pis_account_selection;
        this.accountIdentifier = obj.account_identifier;
        this.swiftBic = obj.swift_bic?.toLowerCase();
        this.isWeb2appEnabled = obj.is_web2app_enabled;
        this.isFakeProvider = obj.is_fake_provider || false;
        if (obj.ui_config) {
            this.uiConfig = {
                fontTone: obj.ui_config.font_tone,
                gradient: obj.ui_config.gradient,
                primaryColor: obj.ui_config.primary_color,
                secondaryColor: obj.ui_config.secondary_color,
                authConfig: obj.ui_config.auth_config || null,
            };
        }
    }

    isChild(): boolean {
        return !!this.parent;
    }

    isPISP(): boolean {
        return this.pisAccountSelection === PisAccountSelection.PISP;
    }

    isDeutschebank(): boolean {
        return this.accountIdentifier === AccountIdentifier.DEUTSCHEBANK;
    }

    isEmbeddedFlow(): boolean {
        return this.accountIdentifier === AccountIdentifier.EMBEDDED;
    }

    hasIsctFeeRefunds(): boolean {
        return ![null, 'FREE'].includes(this.isctFeePolicy);
    }
}
