import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';

import { BnplPaymentSession, Connect, PSU } from '@app-shared/models';
import { StoreService } from '../store/store.service';
import { PaymentCallback } from '@payment/models';
import { PaymentType } from '@app-shared/enums';

@Injectable({
    providedIn: 'root',
})
export class PsuService {
    constructor(private store: StoreService) {}

    get(): Observable<PSU> {
        return this.store.state.selectOnce('psu');
    }

    init(): Observable<PSU> {
        return this.get().pipe(tap((psu: PSU) => this.store.state.update({ psu })));
    }

    initFromResolver(resolver: Connect | PaymentCallback): Observable<Connect | PaymentCallback | BnplPaymentSession> {
        const isBNPL = (resolver as Connect)?.paymentDetails?.providerPaymentType === PaymentType.BUY_NOW_PAY_LATER;

        if (resolver.paymentDetails && !isBNPL && !resolver.paymentDetails.psu) {
            return this.init().pipe(
                tap((psu: PSU) => (resolver.paymentDetails.psu = psu)),
                map(() => resolver),
            );
        }

        this.store.state.update({ psu: resolver.paymentDetails?.psu });
        return of(resolver);
    }
}
