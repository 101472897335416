import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundPage } from '@app/pages/not-found/not-found.page';

const routes: Routes = [
    { path: 'callback/ais', redirectTo: 'v2/payout/callback' }, // TODO remove when we are sure that this route is not used anymore
    { path: 'callback/ocr', redirectTo: 'v2/payout/callback' }, // TODO remove when we are sure that this route is not used anymore
    { path: 'v2/payment', loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule) },
    { path: 'v2/payout', loadChildren: () => import('./modules/payout/payout.module').then((m) => m.PayoutModule) },
    { path: 'v2', loadChildren: () => import('./modules/main/main.module').then((m) => m.MainModule) }, // NOTE : the submodules pis and ais could be moved to the payment and payout modules
    { path: 'callback', loadChildren: () => import('./modules/callback/callback.module').then((m) => m.CallbackModule) }, // NOTE : this route will be replaced with an other path relative to error routes
    { path: '**', pathMatch: 'full', component: NotFoundPage },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
