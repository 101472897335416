export enum TransferState {
    ACCEPTED = 'accepted',
    AUTHORIZED = 'authorized',
    PENDING = 'pending',
    PROCESSING = 'processing',
    COMPLETED = 'completed',
    SENT = 'sent',
    REJECTED = 'rejected',
    INSUFFICIENT = 'insufficient',
    RECEIVED = 'received',
    OVERPAID = 'overpaid',
}
