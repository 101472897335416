import { Component, Input } from '@angular/core';
import { AlertService } from '../services/alert.service';

@Component({
    selector: 'app-alert-container',
    templateUrl: './alert-container.component.html',
    styleUrls: ['./alert-container.component.scss'],
})
export class AlertContainerComponent {
    @Input() id: string;
    @Input() type: 'info' | 'warning';
    @Input() height: number;
    @Input() closable = true;
    @Input() cross: string;

    constructor(private alertService: AlertService) {}

    public onClose(): void {
        this.alertService.close(this.id);
    }
}
