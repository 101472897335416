import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { CountryInfo, Country } from '@app-shared/models';
import { DEFAULT_COUNTRY, DEFAULT_SUPPORTED_COUNTRIES } from '@app-shared/constants';
import { StoreService } from '@app-shared/services';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    private countryInfo: CountryInfo;
    private defaultCountry = DEFAULT_COUNTRY;
    private supportedCountries: string[] = DEFAULT_SUPPORTED_COUNTRIES;
    private supportedCountryChanges: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(this.supportedCountries);

    constructor(private store: StoreService) {}

    init(countryInfo: CountryInfo): void {
        this.initSupportedCountries(countryInfo);

        this.initCountryCode().subscribe((country) => {
            this.setCurrentCountry(country);
            this.supportedCountryChanges.next(this.supportedCountries);
        });
    }

    private initSupportedCountries(countryInfo: CountryInfo): void {
        this.countryInfo = countryInfo;

        if (this.supportedCountryValidator(this.countryInfo?.supportedCountries)) {
            this.supportedCountries = this.countryInfo.supportedCountries.map((country) => country.alphaCode.toLowerCase());
        }
    }

    private initCountryCode(): Observable<string> {
        return this.store.state.selectOnce('country').pipe(
            map((country: string) => {
                if (this.countryIsoCodeValidator(country)) {
                    return country;
                }

                if (this.countryIsoCodeValidator(this.countryInfo?.originCountry)) {
                    return this.countryInfo.originCountry;
                }

                return this.defaultCountry;
            }),
        );
    }

    setCurrentCountry(newCountry: string): void {
        const country = this.getSupportedCountries().includes(newCountry) ? newCountry : this.defaultCountry;
        this.store.state.update({ country });
    }

    getCurrentCountry(): string {
        const country = this.store.state.value.country;
        return this.getSupportedCountries().includes(country) ? country : this.defaultCountry;
    }

    getCurrentCountryChanges(): Observable<string> {
        return this.store.state.select('country');
    }

    setCurrentSupportedCountries(countries: string[]): void {
        this.supportedCountries = countries?.length > 0 ? countries : this.supportedCountries;
        this.supportedCountryChanges.next(this.supportedCountries);
    }

    getSupportedCountries(): string[] {
        return this.supportedCountries.length > 0 ? this.supportedCountries : this.supportedCountries;
    }

    getSupportedCountriesChanges(): Observable<string[]> {
        return this.supportedCountryChanges.asObservable();
    }

    countryIsoCodeValidator(country: string): boolean {
        return country?.length === 2 && this.supportedCountries.includes(country);
    }

    supportedCountryValidator(supportedCountries: Country[]): boolean {
        return supportedCountries?.length > 0 && Object.prototype.hasOwnProperty.call(supportedCountries[0], 'alphaCode');
    }
}
