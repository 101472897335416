import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import en from '@angular/common/locales/en-GB';
import es from '@angular/common/locales/es';
import fr from '@angular/common/locales/fr';
import nl from '@angular/common/locales/nl';
import de from '@angular/common/locales/de';
import it from '@angular/common/locales/it';
import pt from '@angular/common/locales/pt';

import cacheBusting from 'src/i18n-cache-busting.json';

@Injectable({ providedIn: 'root' })
export class FinTranslateService implements TranslateLoader {
    constructor(private http: HttpClient) {
        registerLocaleData(en, 'en');
        registerLocaleData(es, 'es');
        registerLocaleData(fr, 'fr');
        registerLocaleData(nl, 'nl');
        registerLocaleData(de, 'de');
        registerLocaleData(it, 'it');
        registerLocaleData(pt, 'pt');
    }

    getTranslation(lang: string): Observable<any> {
        return this.http.get(`/assets/i18n/${lang}.json?v=${cacheBusting[lang]}`);
    }
}
