import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlsService } from '@app-shared/constants';
import { TransferReason } from '@app-shared/enums';
import { Payment } from '@app-shared/models';
import { Observable } from 'rxjs';
import { StoreService } from '../store/store.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    constructor(private http: HttpClient, private store: StoreService, private apiUrlsService: ApiUrlsService) {}

    update(data: Partial<Payment>, transferReason?: TransferReason): Observable<void> {
        const sessionId = this.store.state.value.sessionId;
        const API_URLS = this.apiUrlsService.getUrls();
        if (!sessionId) {
            console.error('Session_id unknown for pps update');
            return;
        }
        return this.http.patch<void>(`${API_URLS.update_payment}/${sessionId}`, {
            paymentStatus: data.status,
            paymentType: data.providerPaymentType,
            transferState: data.transferState,
            psu: data.psu,
            transferReason,
        });
    }
}
