import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { BehaviorSubject, catchError, distinctUntilChanged, map, Observable, of, skip, Subscription, switchMap, take, tap } from 'rxjs';

import { unsubscribeOnDestroy } from '@app-shared/decorators';
import { Provider } from '@app-shared/models';
import { ApiService, CountryService, SearchService, StoreService } from '@app-shared/services';
import { ConnectService } from '../connect/connect.service';
import { IsctPolicy, Scheme, Scope } from '@app-shared/enums';
import { ProviderExceededLimit } from '@app-shared/models/provider-exceeded-limit.model';
import { FakeProviderService } from '../fake-provider/fake-provider.service';
import { DisabledProviderReason } from '@app-shared/models/disabled-provider-reason.model';

export type ChildrenProviders = Array<Provider>;

@Injectable({ providedIn: 'root' })
export class ProviderService {
    public cancelFavoriteBank = false;

    private initProvidersChanges: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    private currentProvidersChanges: BehaviorSubject<Array<ChildrenProviders>> = new BehaviorSubject<Array<ChildrenProviders>>(null);
    private selectedProviderChanges: BehaviorSubject<Provider> = new BehaviorSubject<Provider>(null);
    private childViewChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private confirmSelectedProviderEvent: BehaviorSubject<Provider> = new BehaviorSubject<Provider>(null);
    private confirmNoScheduledProviderEvent: BehaviorSubject<Provider> = new BehaviorSubject<Provider>(null);

    private providers: Array<ChildrenProviders> = [];

    private reminderProviderSwiftBic: string;
    private selectedProvider: Provider;
    private confirmedProvider: Provider;
    private noScheduledProvider: Provider;
    private isChildView = false;
    private country: string;
    tooltipsEvent: EventEmitter<void> = new EventEmitter();

    constructor(
        private apiService: ApiService,
        private countryService: CountryService,
        private router: Router,
        private connect: ConnectService,
        private store: StoreService,
        private fakeProviderService: FakeProviderService,
    ) {}

    @unsubscribeOnDestroy
    initProviders(): Subscription {
        return this.handleCurrentCountryChanges()
            .pipe(
                tap(() => this.initProvidersChanges.next()),
                switchMap((country) => {
                    if (this.country && country === this.country) {
                        return of(this.providers);
                    }

                    this.country = country;
                    return this.getProviders();
                }),
                map((providers: ChildrenProviders[]) => {
                    // Add some fake providers if needed
                    const computedProviders = this.buildProvidersList(this.country, providers);
                    this.providers = computedProviders;
                    this.currentProvidersChanges.next(this.providers);
                    return this.providers;
                }),
            )
            .subscribe();
    }

    @unsubscribeOnDestroy
    initReminderProviders(params?: Params): Subscription {
        return this.getProviders(params).subscribe((providers) => {
            if (this._hasFavoriteBank(providers, params.swift_bic)) {
                this.reminderProviderSwiftBic = providers[0][0].swiftBic;
                const skipFavProviderAutoSelect = this.store.state.value.tracking?.pisBackToPaymentMethods;
                if (!skipFavProviderAutoSelect) {
                    this.setSelectedProvider(providers[0]);
                }
            } else {
                this.cancelFavoriteBank = true;
                this.setSelectedProvider(null);
            }
        });
    }

    private handleCurrentCountryChanges(): Observable<string> {
        return this.countryService.getCurrentCountryChanges().pipe(distinctUntilChanged());
    }

    private getProviders(params?: Params): Observable<Array<ChildrenProviders>> {
        // Return the list of existing providers list if we do not need to update the list
        return this.apiService.getProviders(params).pipe(
            distinctUntilChanged(),
            catchError((error) => {
                this.router.navigate(['/v2/error'], {
                    queryParams: error.error,
                });
                return of(error);
            }),
            take(1),
        );
    }

    getProvider(providerId: string, technicalServiceProviderId?: string): Observable<Provider> {
        return this.apiService.getProvider(providerId, technicalServiceProviderId).pipe(
            catchError((error) => {
                this.router.navigate(['/v2/error'], {
                    queryParams: error.error,
                });
                return of(error);
            }),
        );
    }

    /**
     * Return the list of providers (with some fake providers added if needed)
     * @param country - alpha code, e.g. 'fr'
     * @param providers - list of providers
     */
    private buildProvidersList(country: string, providers: Array<ChildrenProviders>): Array<ChildrenProviders> {
        const connect = this.connect.get();
        const scope: Scope = connect?.sessionDetails?.scope;
        const hasSmartTransfer = connect?.hasReconciledTransferFeature();

        if (!hasSmartTransfer) {
            // No need to add any fake provider
            return providers;
        }

        const providersFullList = this.fakeProviderService.getProvidersListWithFakeProviders(country, scope, providers);
        return providersFullList;
    }

    getInitProvidersChanges(): Observable<void> {
        return this.initProvidersChanges.asObservable();
    }

    // Current providers

    getCurrentProviders(): Array<ChildrenProviders> {
        return this.providers;
    }

    getCurrentProvidersChanges(): Observable<Array<ChildrenProviders>> {
        return this.currentProvidersChanges.asObservable();
    }

    // Reminder provider SwiftBic

    setReminderProviderSwiftBic(swiftBic: string): void {
        this.reminderProviderSwiftBic = swiftBic?.toLowerCase();
    }

    getReminderProviderSwiftBic(): string {
        return this.reminderProviderSwiftBic;
    }

    // Confirmed provider

    confirmSelectedProvider(): void {
        this.confirmedProvider = this.getSelectedProvider();
        return this.confirmSelectedProviderEvent.next(this.confirmedProvider);
    }

    cancelConfirmedProvider(): void {
        this.confirmedProvider = null;
        this.confirmSelectedProviderEvent.next(this.confirmedProvider);
    }

    getConfirmedProviderChanges(): Observable<Provider> {
        return this.confirmSelectedProviderEvent.asObservable().pipe(skip(1), distinctUntilChanged());
    }

    getConfirmedProvider(): Provider {
        return this.confirmedProvider;
    }

    // Confirmed no scheduled provider

    confirmNoScheduledProvider(): void {
        this.noScheduledProvider = this.getSelectedProvider();
        return this.confirmNoScheduledProviderEvent.next(this.noScheduledProvider);
    }

    getNoScheduledProviderChanges(): Observable<Provider> {
        return this.confirmNoScheduledProviderEvent.asObservable().pipe(skip(1), distinctUntilChanged());
    }

    // Selected provider

    hasSelectedProvider(): boolean {
        return !!this.getSelectedProvider();
    }

    getSelectedProvider(): Provider {
        return this.selectedProvider;
    }

    setSelectedProvider(provider: Provider | Array<Provider>): void {
        this.selectedProvider = this.formatSelectedProvider(provider);
        this.selectedProviderChanges.next(this.selectedProvider);
    }

    getSelectedProviderChanges(): Observable<Provider> {
        return this.selectedProviderChanges.asObservable();
    }

    initPreSelectedProvider(preSelectProviderId: string): void {
        const selected = SearchService.filterMultiArrayByProperty(this.providers, preSelectProviderId, 'id');

        if (selected) {
            this.setSelectedProvider(selected);
        }
    }

    // Childs

    setChildView(isChild: boolean): void {
        this.isChildView = isChild;
        this.childViewChanges.next(this.isChildView);
    }

    getChildViewChanges(): Observable<boolean> {
        return this.childViewChanges.asObservable();
    }

    // helpers

    hasChilds(providers: Array<Provider>): boolean {
        return !!providers.find((provider) => provider.parent);
    }

    isChild(provider: Array<Provider> | Provider): boolean {
        return !Array.isArray(provider) && provider.isChild();
    }

    formatSelectedProvider(provider: Array<Provider> | Provider): Provider {
        return Array.isArray(provider) ? provider[0] : provider;
    }

    atLeastOneWhoHasScheduledPayment(provider: Array<Provider> | Provider): boolean {
        if (!provider) {
            return false;
        }
        return (provider as Array<Provider>).length
            ? (provider as Array<Provider>).some((item) => item.hasScheduledPayments)
            : (provider as Provider).hasScheduledPayments;
    }

    atLeastOneWhoHasAvailable(provider: Array<Provider> | Provider): boolean {
        if ((provider as Array<Provider>).length) {
            return (provider as Array<Provider>).some((item) => ('isAvailable' in item ? item.isAvailable : true));
        }

        if ('isAvailable' in (provider as Provider)) {
            return (provider as Provider).isAvailable;
        }

        return true;
    }

    atLeastOneWhoHasAccountHolders(provider: Array<Provider> | Provider): boolean {
        return (provider as Array<Provider>).length
            ? (provider as Array<Provider>).some((item) => item.hasAccountHolders)
            : (provider as Provider).hasAccountHolders;
    }

    setButtonStyle(provider: Provider, elementRef: ElementRef): void {
        if (provider?.uiConfig && elementRef) {
            const variableStyleName = provider.uiConfig.fontTone === 'light' ? '--fin-color-white-pure' : '--fin-color-dark';
            const textColor = getComputedStyle(elementRef.nativeElement).getPropertyValue(variableStyleName);
            if (textColor) {
                elementRef.nativeElement.style.setProperty('--fintecture-provider-color', textColor);
            }

            if (provider.uiConfig.primaryColor) {
                // Update default state color
                elementRef.nativeElement.style.setProperty('--fintecture-provider-bg-color', provider.uiConfig.primaryColor);

                if (provider.uiConfig.secondaryColor) {
                    // Update hover state color with provided secondary color
                    elementRef.nativeElement.style.setProperty('--fintecture-provider-bg-hover-color', provider.uiConfig.secondaryColor);
                } else {
                    // Use same primary color for the hover state
                    elementRef.nativeElement.style.setProperty('--fintecture-provider-bg-hover-color', provider.uiConfig.primaryColor);
                }
            }

            if (!provider.uiConfig.primaryColor) {
                // Default fallback style (blue bg & darker blue on hover), ensure text is white color
                elementRef.nativeElement.style.setProperty('--fintecture-provider-color', 'var(--fin-color-white-pure)');
            }

            if (provider.uiConfig.gradient) {
                elementRef.nativeElement.style.setProperty('--fintecture-provider-gradient', provider.uiConfig.gradient);
            }
        }
    }

    getProviderExceededLimit(provider: Provider | Provider[]): ProviderExceededLimit | null {
        const { application, paymentDetails } = this.connect.get() ?? {};
        const paymentAmountNumber = paymentDetails?.numberAmount;

        if (!paymentAmountNumber || !provider) {
            // data not loaded yet, no provider limits so far
            return null;
        }

        provider = this.formatSelectedProvider(provider);

        let minAmount = provider.minAmount,
            minAmountNumber = provider.minAmountNumber,
            maxAmount = provider.maxAmount,
            maxAmountNumber = provider.maxAmountNumber,
            scheme = Scheme.SEPA;

        if (application?.isctPolicy === IsctPolicy.ONLY_ISCT || paymentDetails?.paymentScheme === Scheme.INSTANT_SEPA) {
            minAmount = provider.isctMinAmount;
            minAmountNumber = provider.isctMinAmountNumber;
            maxAmount = provider.isctMaxAmount;
            maxAmountNumber = provider.isctMaxAmountNumber;
            scheme = Scheme.INSTANT_SEPA;
        }

        switch (true) {
            case minAmountNumber && paymentAmountNumber < minAmountNumber:
                return { limit: 'min', amount: minAmount, scheme };
            case maxAmountNumber && paymentAmountNumber > maxAmountNumber:
                return { limit: 'max', amount: maxAmount, scheme };
            default:
                return null;
        }
    }

    getDisabledReason(provider: Array<Provider> | Provider): DisabledProviderReason | null {
        provider = this.formatSelectedProvider(provider);

        if (!provider) {
            return null;
        }

        if (provider.isFakeProvider) {
            return { reason: 'not-integrated' };
        }

        if (!provider.isAvailable) {
            return { reason: 'technical-error' };
        }

        const applicationIsISCTonly = this.connect.get().application.isctPolicy === IsctPolicy.ONLY_ISCT;
        const providerAllowsInstantSepa = provider.hasPaymentsInstantSepa;
        if (applicationIsISCTonly && !providerAllowsInstantSepa) {
            return { reason: 'instant-not-allowed' };
        }

        const exceededLimit = this.getProviderExceededLimit(provider);
        if (exceededLimit) {
            return {
                reason: `exceeded-${exceededLimit.limit}-limit`,
                amount: exceededLimit.amount,
            };
        }

        return null;
    }

    canSuggestSmartTransferFallback(provider: Array<Provider> | Provider): boolean {
        const hasReconciledTransferFeature = this.connect.get().hasReconciledTransferFeature();
        const hasProviderDisabledReason = !!this.getDisabledReason(provider);

        return hasReconciledTransferFeature && hasProviderDisabledReason;
    }

    private _hasFavoriteBank(providers: Array<ChildrenProviders>, swiftBic: string): boolean {
        // Check if the favorite bank is returned and available when fetching all providers, as it could be disabled
        return providers.some((provider) => {
            return provider.some(
                (childrenProvider) =>
                    childrenProvider.swiftBic === swiftBic.toLowerCase() &&
                    (childrenProvider.isAvailable || this.canSuggestSmartTransferFallback(childrenProvider)),
            );
        });
    }
}
