export class Order {
    shopName?: string;
    amount?: string;
    date?: string;
    dueDate?: string;
    days?: string;
    redirectUrl?: string;
    pisFallbackLink?: string;
    psuCompany?: string;

    constructor(obj: any) {
        this.shopName = obj.shopName;
        this.amount = obj.amount;
        this.dueDate = obj.dueDate;
        this.date = obj.date;
        this.days = obj.days;
        this.redirectUrl = obj.redirectUrl;
        this.pisFallbackLink = obj.pisFallbackLink;
        this.psuCompany = obj.psuCompany;
    }
}
