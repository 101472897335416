import { Functionality, OnboardingStatus } from '@app-shared/enums';
import { buildFunctionalities } from '@app-shared/helpers';
import { Onboarding, Payment, Session } from '@app-shared/models';

export class RefundedPayment {
    paymentDetails: Payment;
    sessionDetails: Session;
    onboarding?: Onboarding;
    iban?: string;
    redirectionUrl?: string;
    redirectionState?: string;
    allowPhoneVerification: boolean;
    allowEmailVerification: boolean;
    functionalities: Functionality[];

    constructor(obj: any) {
        this.paymentDetails = obj.payment_details ? new Payment(obj.payment_details) : undefined;
        this.sessionDetails = obj.connect_session ? new Session(obj.connect_session) : undefined;
        this.onboarding = obj.onboarding ? new Onboarding(obj.onboarding) : undefined;
        this.iban = obj.iban;
        this.redirectionUrl = obj.redirect_info?.redirect_uri;
        this.redirectionState = obj.redirect_info?.state;
        this.allowPhoneVerification = obj.allow_phone_verification;
        this.allowEmailVerification = obj.allow_email_verification;
        this.functionalities = buildFunctionalities(obj.functionalities);
    }

    trackingReady(): boolean {
        return (
            this.paymentDetails.refundTrackingReady() ||
            (this.paymentDetails.isIbanRequired() &&
                [OnboardingStatus.FAILED, OnboardingStatus.PENDING, OnboardingStatus.COMPLETED].includes(this.onboarding?.status))
        );
    }
}
