import { Component, HostBinding, OnInit } from '@angular/core';
import { EMPTY, of, Subscription } from 'rxjs';

import { unsubscribeOnDestroy } from '@app-shared/decorators';
import { LoaderState } from '@app-shared/models';
import { BeneficiaryService, LoaderService } from '@app-shared/services';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    public data: LoaderState;
    public beneficiary: string;
    public isMobile = this.deviceService.isMobile();

    @HostBinding('hidden') get hidden(): boolean {
        return !this.data?.loading;
    }

    get isSpinnerShown(): boolean {
        return this.data?.loading && !this.data?.error;
    }

    constructor(private loaderService: LoaderService, private beneficiaryService: BeneficiaryService, private deviceService: DeviceDetectorService) {
        this.data = {
            title: 'redirection',
            loading: true,
            bankName: null,
            text: null,
            error: null,
            execute: of(EMPTY),
        };
    }

    ngOnInit(): void {
        this.initLoader();
        this.initBeneficiary();
    }

    @unsubscribeOnDestroy
    private initBeneficiary(): Subscription {
        return this.beneficiaryService.get().subscribe((beneficiary) => (this.beneficiary = beneficiary?.name));
    }

    @unsubscribeOnDestroy
    initLoader(): Subscription {
        return this.loaderService.loader.subscribe((loader) => {
            Object.assign(this.data, loader);
        });
    }

    reload(): void {
        this.data.execute.subscribe(() => window.location.reload());
    }

    get errorTitle(): string {
        switch (this.data?.title) {
            case 'redirection':
                return 'shared.loader.error.title-redirection';
            case 'provider_error':
            case 'unavailable':
            default:
                return 'shared.loader.error.title-unavailable';
        }
    }

    get errorText(): string {
        if (!this.data?.bankName) {
            return 'shared.loader.error.first-row';
        }
        return this.data.title === 'provider_error' ? 'shared.loader.error.first-row-provider-error' : 'shared.loader.error.first-row-bank';
    }
}
