import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    private collapsed = this.deviceService.isMobile();

    constructor(private deviceService: DeviceDetectorService) {}

    showLegal(): boolean {
        return !this.collapsed;
    }

    getCollapseIcon(): string {
        if (!this.canCollapse()) {
            return;
        }

        const direction = this.collapsed ? 'up' : 'down';
        return `keyboard_double_arrow_${direction}`;
    }

    toggleCollapsed(): void {
        if (!this.canCollapse()) {
            return;
        }

        this.collapsed = !this.collapsed;
    }

    private canCollapse(): boolean {
        return this.deviceService.isMobile();
    }
}
