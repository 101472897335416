import { Observable } from 'rxjs';

export interface LoaderState {
    title?: 'redirection' | 'unavailable' | 'provider_error';
    loading?: boolean;
    bankName?: string;
    text?: string;
    error?: boolean;
    leaveApplication?: boolean;
    execute?: ReturnType<() => Observable<any>>;
}
