import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { AlertContainerComponent } from './alert-container/alert-container.component';
import { NotificationModule } from '@fintecture-npm/angular-ui';

@NgModule({
    imports: [CommonModule, PortalModule, OverlayModule, NotificationModule],
    declarations: [AlertComponent, AlertContainerComponent],
    exports: [AlertComponent],
})
export class AlertModule {}
