export class Address {
    city: string;
    country: string;
    street: string;
    streetNumber: string;
    zip?: string;

    constructor(obj: any) {
        this.city = obj.city;
        this.country = obj.country;
        this.street = obj.street;
        this.streetNumber = obj.streetNumber;
        this.zip = obj.zip;
    }
}
