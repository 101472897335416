export class PSU {
    name?: string;
    email?: string;
    phone?: string;
    incorporation?: string; // registration number (SIRET)
    identifier?: string;
    identifierType?: 'phone' | 'email';
    completed?: boolean;

    constructor(obj: any) {
        if (obj.name) this.name = obj.name;
        if (obj.email) this.email = obj.email;
        if (obj.phone) this.phone = obj.phone;
        if (obj.incorporation) this.incorporation = obj.incorporation;
        if (obj.identifier) this.identifier = obj.identifier;
        if (obj.identifier_type) this.identifierType = obj.identifier_type;
        this.completed = obj.completed || false;
    }
}
