import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LoaderState } from '@app-shared/models';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    private loaderSubject = new BehaviorSubject<LoaderState>({
        loading: true,
    });

    loader = this.loaderSubject.asObservable();

    set(params: LoaderState): void {
        const payload = { ...this.loaderSubject.getValue(), ...params };

        // resets
        if (!params.error) {
            payload.error = null;
        }

        if (!params.text) {
            payload.text = null;
        }

        if (!params.bankName) {
            payload.bankName = null;
        }

        if (!params.title) {
            payload.title = null;
        }

        this.loaderSubject.next(payload);
    }

    getLoaderState(): LoaderState {
        return this.loaderSubject.getValue();
    }
}
