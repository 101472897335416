import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularIbanModule } from 'angular-iban';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FinTranslateService } from '@app-shared/services';
import { AlertSandboxComponent } from './components/alert-sandbox/alert-sandbox.component';
import { LoaderComponent } from '@app/components/loader/loader.component';
import { CommonModule } from '@angular/common';
import { FooterModule } from '@app-shared/modules';
import { InitializerModule } from './initializer/initializer.module';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { ASSETS_URL, FIN_DISPLAY_STYLE_TOKEN, IllustrationModule, LoaderModule } from '@fintecture-npm/angular-ui';
import { DeviceFingerprintInterceptor } from '@app-shared/interceptors/device-fingerprint/device-fingerprint.interceptor';

import { APP_CONFIG, AppConfig } from '../app.config';

@NgModule({
    declarations: [AppComponent, AlertSandboxComponent, LoaderComponent, NotFoundPage],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: FinTranslateService,
            },
        }),
        AngularIbanModule,
        FooterModule,
        InitializerModule,
        IllustrationModule,
        LoaderModule,
    ],
    providers: [
        {
            provide: ASSETS_URL,
            useFactory: (config: AppConfig) => config.assets_url,
            deps: [APP_CONFIG],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DeviceFingerprintInterceptor,
            multi: true,
        },
        {
            provide: FIN_DISPLAY_STYLE_TOKEN,
            useValue: 'generic',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
