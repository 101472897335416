import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProviderListSkeletonLoaderComponent } from './components/provider-list-skeleton-loader/provider-list-skeleton-loader.component';

@NgModule({
    imports: [CommonModule, NgxSkeletonLoaderModule],
    declarations: [ProviderListSkeletonLoaderComponent],
    exports: [ProviderListSkeletonLoaderComponent],
})
export class SkeletonLoaderModule {}
