import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from './footer.component';
import { IconModule } from '@fintecture-npm/angular-ui';

@NgModule({
    imports: [CommonModule, TranslateModule, IconModule],
    declarations: [FooterComponent],
    exports: [FooterComponent],
})
export class FooterModule {}
