export class Kyu {
    id: string;
    favoriteBic?: string;
    isActive: boolean;
    lastAuthenticatedBic?: string;

    constructor(obj) {
        this.id = obj.id;
        this.favoriteBic = obj.favorite_bic;
        this.isActive = obj.is_active; // set after psu has made a successful payment
        this.lastAuthenticatedBic = obj.last_authenticated_bic; // set after psu has logged in to a bank
    }

    isFirstTimePayer(): boolean {
        return !this.lastAuthenticatedBic;
    }
}
