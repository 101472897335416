import { Subscription } from 'rxjs';

export function unsubscribeOn(unsubscribeMethod: string) {
    return function (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<() => Subscription>) {
        // Override original method to add subscription
        const originalMethod = descriptor.value;
        descriptor.value = function (...args) {
            if (!this.__subscription || this.__subscription.closed) {
                this.__subscription = new Subscription();
            }

            const result = originalMethod.apply(this, args);
            this.__subscription.add(result);

            return result;
        };

        // Override original method to unsubscribe
        const originalUnsubscribeMethod = target[unsubscribeMethod];
        target[unsubscribeMethod] = function (...args) {
            this.__subscription?.unsubscribe();
            if (originalUnsubscribeMethod) originalUnsubscribeMethod.apply(this, args);
        };

        return descriptor;
    };
}
