<ng-template [ngIf]="useOverlay" [ngIfElse]="animationContainer">
    <ng-template cdk-portal>
        <ng-container *ngTemplateOutlet="animationContainer"></ng-container>
    </ng-template>
</ng-template>
 
<ng-template #animationContainer>
    <ng-template [ngIf]="animation" [ngIfElse]="alertContainer">
        <div *ngIf="showing" @animate class="animation-wrapper">
            <ng-container *ngTemplateOutlet="alertContainer"></ng-container>
        </div>
    </ng-template>
</ng-template>
 
<ng-template #alertContainer>
   <app-alert-container  *ngIf="showing"
            [hidden]="hidden"
            role="alert"
            [type]="type"
            [id]="id">
       <ng-content></ng-content>
   </app-alert-container>
</ng-template>
