import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { checkURL } from '@app-shared/helpers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoaderService } from '../loader/loader.service';
import { DataCollectingService } from '../data-collecting/data-collecting.service';
import { DataCollectingEvent } from '@app-shared/enums';
import { Observable, finalize } from 'rxjs';

const REDIRECTION_TIMEOUT = 5000;

export interface RedirectionOptions {
    target?: '_blank' | '_self' | '_parent' | '_top';
    forceReload?: boolean;
    sendRedirectionEvent?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class RedirectService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private deviceService: DeviceDetectorService,
        private router: Router,
        private loaderService: LoaderService,
        private dataCollectingService: DataCollectingService,
    ) {}

    public redirect(url: string, options?: RedirectionOptions): Promise<boolean> {
        if (!url) {
            return Promise.reject('invalid url');
        }

        if (this.isInternalRedirection(url, options)) {
            return this.internalRedirection(url);
        }

        return new Promise((resolve) => {
            if (options?.sendRedirectionEvent) {
                return this.handleRedirectionEvents(() => {
                    this.handleDeviceRedirection(url, options?.target);
                    resolve(true);
                }).subscribe();
            }

            this.handleDeviceRedirection(url, options?.target);
            resolve(true);
        });
    }

    private isInternalRedirection(url: string, options: RedirectionOptions): boolean {
        return url.includes(this.document.location.origin) && checkURL(url) && !options?.forceReload;
    }

    private handleDeviceRedirection(url: string, target): void {
        try {
            if (!this.deviceService.isDesktop() || target === '_blank') {
                this.documentElementRedirection(url, target);
                return;
            }

            this.classicRedirection(url, target);
        } catch (_) {
            this.classicRedirection(url, target);
        }
    }

    classicRedirection(url: string, target = '_self'): void {
        this.loaderService.set({ leaveApplication: true });
        window.open(url, target);
    }

    documentElementRedirection(url: string, target: string): void {
        const universalLink = this.document.createElement('a');

        universalLink.setAttribute('href', url);

        if (target) {
            universalLink.setAttribute('target', target);
        }

        this.document.body.appendChild(universalLink).click();
    }

    internalRedirection(url: string): Promise<boolean> {
        const urlObject = new URL(url);
        return this.router.navigate([urlObject.pathname], { queryParams: Object.fromEntries(urlObject.searchParams.entries()) });
    }

    private handleRedirectionEvents(callback: () => void): Observable<void> {
        return this.dataCollectingService.sendEvent(DataCollectingEvent.CONNECT_REDIRECTION_INITIATED).pipe(
            finalize(() => {
                callback();
                setTimeout(() => this.dataCollectingService.sendEvent(DataCollectingEvent.CONNECT_REDIRECTION_DELAYED).subscribe(), REDIRECTION_TIMEOUT);
            }),
        );
    }
}
