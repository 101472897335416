export class CoverageDueDate {
    customConfig: boolean;
    maxDuration: number;
    dueAt: Date;
    issuedAt: Date;

    constructor(obj: any) {
        this.customConfig = obj.custom_config;
        this.dueAt = obj.due_at ? new Date(obj.due_at) : null;
        this.issuedAt = obj.issued_at ? new Date(obj.issued_at) : null;
        this.maxDuration = obj.max_duration;
    }
}
