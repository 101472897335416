import { Country } from './country.model';

export class CountryInfo {
    originCountry?: string;
    supportedCountries: Country[];

    constructor(obj: any) {
        this.originCountry = obj.origin_country;
        this.supportedCountries = obj.supported_countries?.map((country) => new Country(country));
    }
}
