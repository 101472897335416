import { datadogLogs } from '@datadog/browser-logs';

export function initializeDatadog(config: any): void {
    // This is harcoded because we don't want to expose the datadog token to the client
    // as frontend is unique but configurable for each environment (12factors)
    let clientToken: string;
    let ddSite: string;

    // Switch case
    switch (config.env_name) {
        case 'test':
            ddSite = 'datadoghq.eu';
            clientToken = 'pub90905692390739a2cc63c479429035fc';
            break;
        case 'pp':
            ddSite = 'datadoghq.eu';
            clientToken = 'pub4a03fe3853a482fc73a13eea4c957a0b';
            break;
        default:
            ddSite = null;
            clientToken = null;
            break;
    }

    if (clientToken && ddSite) {
        datadogLogs.init({
            clientToken: clientToken,
            site: ddSite,
            env: config.env_name,
            forwardErrorsToLogs: true,
            sampleRate: 100,
            service: 'connect-2-frontend',
        });
    }
}
