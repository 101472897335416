import { trigger, transition, style, animate } from '@angular/animations';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { unsubscribeOnDestroy } from '@app-shared/decorators';
import { Subscription } from 'rxjs';
import { AlertService } from './services/alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    animations: [
        trigger('animate', [
            transition(':enter', [style({ transform: 'translateY(-100%)' }), animate('300ms ease-in-out', style({ transform: 'translateY(0)' }))]),
            transition(':leave', [style({ transform: 'translateY(0)' }), animate('300ms ease-in-out', style({ transform: 'translateY(-100%)' }))]),
        ]),
    ],
})
export class AlertComponent implements OnDestroy, OnInit {
    @Input() id: string;
    @Input() type: 'info' | 'warning';
    @Input() hidden = false;
    @Input() useOverlay = true;
    @Input() animation = true;
    @ViewChild(CdkPortal) contentTemplate: CdkPortal;
    protected overlayRef: OverlayRef;

    public showing = false;

    constructor(protected overlay: Overlay, private alertService: AlertService) {
        this.alertService.register(this);
    }

    ngOnInit(): void {
        this.handleCurrentChanges();
    }

    ngOnDestroy(): void {
        setTimeout(() => this.alertService.unregister(this));
    }

    @unsubscribeOnDestroy
    handleCurrentChanges(): Subscription {
        return this.alertService.state.select('current').subscribe((alert: AlertComponent) => {
            if (alert?.identifier === this.id) {
                this.show();
            } else if (!alert && this.showing) {
                this.hide();
            }
        });
    }

    get identifier(): string {
        return this.id;
    }

    public show(): void {
        // Wait for contentTemplate to be loaded
        setTimeout(() => {
            if (this.useOverlay) {
                this.overlayRef = this.overlay.create();
                this.overlayRef.attach(this.contentTemplate);
            }
            this.showing = true;
        });
    }

    public hide(): void {
        setTimeout(() => {
            if (this.useOverlay) {
                this.overlayRef.detach();
            }
            this.showing = false;
        });
    }
}
