import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { CardService } from '@fintecture-npm/angular-ui';
import { filter, first, switchMap } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class CguService {
    // Show cgu only on the initial screen
    private readonly $contentState = toSignal(this.cardService.selectState('contentState'));

    // Loader is used to avoid set the initial state if value is not loaded
    private readonly $initialState = toSignal(
        this.loaderService.loader.pipe(
            filter((loader) => !loader.loading),
            switchMap(() => this.cardService.selectState('contentState')),
            filter(Boolean),
            first(),
        ),
    );

    readonly $force: WritableSignal<'show' | 'hide' | null> = signal(null);

    private readonly $isForcedShown = computed(() => this.$force() === 'show');
    private readonly $isForcedHidden = computed(() => this.$force() === 'hide');

    readonly $shouldShowCgu = computed(() => {
        return !this.$isForcedHidden() && (this.$isForcedShown() || this.$initialState() === this.$contentState());
    });

    constructor(private cardService: CardService, private loaderService: LoaderService) {}
}
